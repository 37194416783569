<template>
  <ifc-dialog
    v-model="localOpen"
    :title="title"
    @onClose="handleClose"
    max-width="360px"
  >
    <div class="ifc-store-stock-import__loading" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      />
    </div>
    <v-form ref="form" lazy-validation>
      <v-row v-if="!loading">
        <v-col class="ifc-store-stock-import__location" cols="12">
          <ifc-combobox
            class="ifc-combo-style"
            appendIcon="expand_more"
            v-model="locationId"
            :label="`${$t('label.location')}`"
            placeholder="Selecione"
            keyLabel="name"
            keyValue="id"
            :loading="loading"
            :options="locationsList"
            :rules="$validator().isRequired().rules()"
            clearable
          />
        </v-col>
      </v-row>

      <v-row v-if="!loading">
        <v-col class="ifc-store-stock-import__upload" cols="12">
          <ifc-upload
            :subtitle="$t('ImportStoreStockModal.fileFormat')"
            :multiple="false"
            :content="`${
              importFile
                ? importFile.name
                : $t(`ImportStoreStockModal.uploadComponentContentMessage`)
            }`"
            accept=".xlsx"
            ref="inputFile"
            @onUpload="handleChangeFileSelection"
          />
        </v-col>
        <div
          v-show="importFileMessage"
          class="ifc-store-stock-import__upload_error"
        >
          {{ importFileMessage }}
        </div>
      </v-row>

      <v-row v-if="!loading">
        <v-col class="ifc-store-stock-import__buttons" cols="6">
          <ifc-button
            class="ifc-px-16"
            color="information-100"
            :label="$t('ImportStoreStockModal.uploadButton')"
            @click="handleUpload"
            :disabled="!readyToUpload"
          />
        </v-col>
        <v-col class="ifc-store-stock-import__buttons" cols="6">
          <ifc-button
            :href="`${getTemplatePath}/files/import_template.xlsx`"
            download
            class="ifc-px-16"
            color="information-100"
            :label="$t('ImportStoreStockModal.downloadTemplate')"
          />
        </v-col>
      </v-row>
    </v-form>
  </ifc-dialog>
</template>

<script>
import { getAccountsLocation } from '@/services/accounts'
import { uploadImportFile } from '@/services/storeStocksImport'
import { name } from '../../../package.json'

export default {
  name: 'StoreStockImportUpload',
  components: {},
  filters: {},
  props: {
    id: {
      type: String
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    inventory: {},
    locationsList: [],
    loading: false,
    locationId: undefined,
    importFile: undefined
  }),
  computed: {
    localOpen: {
      get() {
        return this.value
      },
      set(open) {
        this.$emit('input', open)
      }
    },
    title() {
      return this.$t('ImportStoreStockModal.title')
    },
    readyToUpload() {
      return this.locationId && this.importFile && this.checkFileFormat()
    },
    importFileMessage() {
      if (this.importFile?.name && !this.checkFileFormat()) {
        return `${this.$t('ImportStoreStockModal.invalidFileTypeMessage')}`
      }

      return null
    },
    getTemplatePath() {
      const path = window.System ? window.System.resolve(name) : ''
      return path.replace('/js/app.js', '')
    }
  },
  mounted() {
    this.getLocationList()
  },
  methods: {
    checkFileFormat() {
      if (this.importFile?.name && !this.importFile.name.match(/\.xlsx$/gi)) {
        return false
      }

      return true
    },
    validateForm() {
      return this.$refs.form.validate() && this.checkFileFormat()
    },
    handleClose() {
      this.$emit('input', false)
    },
    handleChangeFileSelection(fileObjects) {
      this.importFile = fileObjects[0]
    },
    async handleUpload() {
      if (!this.validateForm()) {
        return
      }

      try {
        this.loading = true
        await uploadImportFile(this.locationId, this.importFile)
        this.localOpen = false
      } catch (e) {
        const errorMessage = e.response?.data?.errors
          ? e.response?.data?.errors
          : 'Ocorreu um erro inesperado'
        this.$toastify(errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    async getLocationList() {
      this.loading = true
      const locationsList = await getAccountsLocation()
      this.locationsList = locationsList
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '@infralabs/infra-components-vue/dist/styles/scss/global/color';

.ifc-store-stock-import__location {
  display: flex;
  justify-content: center;
  padding: 0;
}
.ifc-store-stock-import__upload {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: -30px;
  text-align: center;
  width: 100%;
}
.ifc-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ifc-store-stock-import__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 338px;
}
.ifc-store-stock-import__buttons {
  justify-content: center;
  padding: 0 7px;
}
.ifc-store-stock-import__upload_error {
  color: #e11d2b;
  font-size: 12px;
  padding: 0 0 10px 10px;
}
.ifc-plugins {
  z-index: 999999 !important;
}
</style>
<style lang="scss" scoped>
.modal {
  &-footer {
    border-top: 1px solid $color-neutral-200;
  }
}
.custom {
  &-icon {
    ::v-deep i {
      font-size: 12px;
    }
  }
}
</style>
