const INVENTORY_CRITICAL_STOCK = 'INVENTORY_CRITICAL_STOCK'
const INVENTORY_STOCK_LOAD = 'INVENTORY_STOCK_LOAD'
const STORE_STOCKS_IMPORT_FILE_PENDING = 'STORE_STOCKS_IMPORT_FILE_PENDING'
const STORE_STOCKS_IMPORT_FILE_PROCESSING =
  'STORE_STOCKS_IMPORT_FILE_PROCESSING'
const STORE_STOCKS_IMPORT_FILE_FINISHED = 'STORE_STOCKS_IMPORT_FILE_FINISHED'
export const INVENTORY_EXPORT_REPORT = 'INVENTORY_EXPORT_REPORT'
export const STORE_STOCKS_IMPORT_FILE = 'STORE_STOCKS_IMPORT_FILE'
export const NOTIFICATION_TYPES = [
  INVENTORY_CRITICAL_STOCK,
  INVENTORY_STOCK_LOAD,
  INVENTORY_EXPORT_REPORT,
  STORE_STOCKS_IMPORT_FILE_PENDING,
  STORE_STOCKS_IMPORT_FILE_PROCESSING,
  STORE_STOCKS_IMPORT_FILE_FINISHED
]
export const NOTIFICATION_TYPE = {
  [INVENTORY_CRITICAL_STOCK]: {
    icon: 'ph-warning-thin',
    color: 'error-200',
    title: 'Estoque crítico',
    text: 'Estoque crítico da configuração {name} atingido'
  },
  [INVENTORY_STOCK_LOAD]: {
    icon: 'ph-check-circle-thin',
    color: 'success-200',
    title: 'Sucesso',
    text: 'Uma nova carga de estoque foi sincronizada'
  },
  [INVENTORY_EXPORT_REPORT]: {
    icon: 'ph-file-arrow-down',
    color: 'success-200',
    title: 'Sucesso',
    text: 'Sua planilha está disponível'
  },
  [STORE_STOCKS_IMPORT_FILE_PENDING]: {
    icon: 'ph-check-circle-thin',
    color: 'success-200',
    title: 'Importação pendente',
    text: 'A importação do arquivo {name} será realizada em breve'
  },
  [STORE_STOCKS_IMPORT_FILE_PROCESSING]: {
    icon: 'ph-check-circle-thin',
    color: 'success-200',
    title: 'Importação em andamento',
    text: 'O arquivo {name} está sendo processado'
  },
  [STORE_STOCKS_IMPORT_FILE_FINISHED]: {
    icon: 'ph-check-circle-thin',
    color: 'success-200',
    title: 'Importação finalizada',
    text: 'A importação do arquivo {name} finalizou. O relatório de importação está disponível'
  }
}
