export default {
  total: 0,
  pages: 0,
  tableLoading: true,
  filters: {},
  sellersOptions: [],
  currentConfig: {},
  salesChannel: [],
  locations: [],
  listStoreStock: [],
  step: '',
  listPerPage: [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 }
  ],
  optionInfo: {},
  parameters: [],
  criticalStock: null,
  inventoryIdDetail: {},
  deselectedItens: [],
  setLocationConfig: [],
  setStockProtection: {},
  changeStockProtection: null,
  locationConfigProtection: {},
  openRegister: false
}
