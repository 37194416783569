import { ApiAdm } from '@/plugins/axios/axios'

const URL = '/accounts/locations'

export const getAccountsLocation = async () => {
  const { data } = await ApiAdm.get(URL, {
    params: {
      accountType: 'location',
      perPage: Number.MAX_SAFE_INTEGER
    }
  })

  return data?.data || []
}
