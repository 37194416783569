<template>
  <v-row class="ifc-filter-list ifc-px-8">
    <v-col cols="12" sm="8" md="9" class="align-self-center ifc-px-4">
      <ifc-input
        v-model="filters.name"
        :placeholder="`${$t('label.searchBy')} ${$t('label.description')}`"
        prependIcon="search"
      />
    </v-col>
    <v-col cols="12" sm="4" md="3" class="align-self-end ifc-px-4">
      <ifc-combobox
        class="ifc-combo-style"
        appendIcon="expand_more"
        v-model="filters.type"
        :options="listTypes"
        :label="$t('label.salesChannelType')"
        :placeholder="$t('label.select')"
        clearable
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FilterSalesChannels',
  data() {
    return {
      panel: [0],
      filters: {
        name: '',
        type: ''
      },
      listTypes: [
        {
          label: 'web',
          value: 'web'
        },
        {
          label: 'offline',
          value: 'offline'
        }
      ]
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('filters', this.filters)
      },
      deep: true
    }
  },
  methods: {
    getStatus(s) {
      const name = this.filters.name
      const status = s
      this.filters = {
        name: name
      }
      if (status.indexOf('shipToAddress') > -1) {
        this.filters.shipToAddress = true
      }
      if (status.indexOf('shipToStore') > -1) {
        this.filters.shipToStore = true
      }
      if (status.indexOf('pickupStore') > -1) {
        this.filters.pickupStore = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-text-field__details {
  display: none !important;
}
</style>
