<template>
  <ifc-container
    lass="atc"
    :breadcrumb="breadcrumbs"
    :title="$t('pageAtcList.title')"
  >
    <template #action>
      <notification-menu
        @onVisible="$handleChangeVisible"
        @onReadAll="$handleReadAll"
        :options="$notifications"
        :total-unread="$totalUnreadNotifications"
      />
    </template>
    <filter-list
      :chkStore="chkStore"
      @filters="filterTable"
      @register="chkDataConfig"
      @errApi="showNotifyInfo()"
    />
    <ifc-table
      :headers="headers"
      :items="content.data"
      :options.sync="options"
      :loading="loading"
    >
      <tbody>
        <tr v-for="(item, i) in content.data" :key="i">
          <td>{{ item.name }}</td>
          <td>
            <ifc-tooltip
              v-bind="$props"
              bottom
              max-width="600"
              background-color="information-50"
              text-color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                  {{
                    item.salesChannelName.indexOf(',') > -1
                      ? item.salesChannelName.replaceAll(',', ', ')
                      : item.salesChannelName
                  }}
                </div>
              </template>
              <span
                v-html="
                  item.salesChannelName.indexOf(',') > -1
                    ? item.salesChannelName.replaceAll(',', ', ')
                    : item.salesChannelName
                "
              >
              </span>
            </ifc-tooltip>
          </td>
          <!-- <td>{{ item.deliveryMethods }}</td> -->
          <td>
            <ifc-tooltip
              v-bind="$props"
              bottom
              max-width="600"
              background-color="information-50"
              text-color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                  {{
                    item.locationName.indexOf(',') > -1
                      ? item.locationName.replaceAll(',', ', ')
                      : item.locationName
                  }}
                </div>
              </template>
              <span
                v-html="
                  item.locationName.indexOf(',') > -1
                    ? item.locationName.replaceAll(',', ', <br>')
                    : item.locationName
                "
              >
              </span>
            </ifc-tooltip>
          </td>
          <td>{{ item.createdAt }}</td>
          <td>
            <ifc-chip
              :label="$t(`status.active.short`)"
              background-color="success-50"
              text-color="success-200"
              v-if="item.active"
            />
            <ifc-chip
              :label="$t(`status.inactive.short`)"
              background-color="neutral-300"
              text-color="neutral-400"
              v-else
            />
          </td>
          <td>
            <ifc-button
              class="ifc-px-16"
              color="primary"
              icon="edit"
              @click="editAtcConfig(item.id)"
              size="extra-small"
            />
          </td>
          <td>
            <ifc-button
              class="ifc-px-16"
              color="primary"
              icon="delete"
              @click="deleteAtcConfig(item.id, item.name)"
              size="extra-small"
            />
          </td>
          <td>
            <ifc-button
              class="ifc-px-16"
              color="primary"
              icon="remove_red_eye"
              @click="seeMoreAtcConfig(item)"
              size="extra-small"
            />
          </td>
        </tr>
      </tbody>
    </ifc-table>
    <ifc-pagination
      class="ifc-mt-28"
      :totalPages="content.pages"
      :perPageOptions="listPerPage"
      :perPageDefault="options.itemsPerPage"
      @onChangePage="setOnChangePage"
      @onChangePerPage="setOnChangePerPage"
    />
  </ifc-container>
</template>

<script>
import NotificationMenu from '@/components/Notification/menu.vue'
import { ApiAdm } from '@/plugins/axios/axios'
import FilterList from '@/components/Atc/Filters/FilterAtcConfigList.vue'
import MixinNotifications from '@/mixins/notifications'
import MixinCheckstore from '@/mixins/checkstore'

export default {
  name: 'AvailableToCommerce',
  mixins: [MixinNotifications, MixinCheckstore],
  components: {
    FilterList,
    NotificationMenu
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.atc.StockAvailableSale')
        }
      ],
      headers: [
        {
          text: this.$t('label.name'),
          sortable: true,
          value: 'name',
          class: 'text-left'
        },
        {
          text: this.$t('label.salesChannels'),
          sortable: true,
          value: 'salesChannels',
          class: 'text-left'
        },
        // {
        //   text: this.$t('label.deliveryMethods'),
        //   sortable: true,
        //   value: 'deliveryMethods',
        //   class: 'text-left'
        // },
        {
          text: this.$t('label.locations'),
          sortable: true,
          value: 'locations',
          class: 'text-left'
        },
        {
          text: this.$t('label.createdAt'),
          sortable: true,
          value: 'createdAt',
          class: 'text-left'
        },
        {
          text: this.$t('label.status'),
          sortable: true,
          value: 'status',
          class: 'text-center'
        },
        { text: '', sortable: false, value: 'edit' },
        { text: '', sortable: false, value: 'delete' },
        { text: '', sortable: false, value: 'ver mais' }
      ],
      options: {},
      listPerPage: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false
    }
  },
  created() {
    this.$setNotificationPerPage(5)
    this.$fetchNotifications()
  },
  watch: {
    options: {
      handler() {
        this.getOptionsApi()
      },
      deep: true
    }
  },
  methods: {
    async chkDataConfig() {
      let salesChannelsData = null
      let listConfigData = null
      let locationsData = null
      await ApiAdm.get('/accounts/sales-channels')
        .then((res) => {
          const result = res.data.data
          listConfigData = result.length
        })
        .catch(() => {
          listConfigData = 0
        })
      await ApiAdm.get('/parameters/sales-channels?hasParameter=false')
        .then((res) => {
          const result = res.data.data
          salesChannelsData = result.length
        })
        .catch(() => {
          salesChannelsData = 0
        })
      await ApiAdm.get('/accounts')
        .then((res) => {
          const result = res.data.data
          locationsData = result.length
        })
        .catch(() => {
          locationsData = 0
        })
      if (
        listConfigData !== null &&
        salesChannelsData !== null &&
        locationsData !== null
      ) {
        if (listConfigData === 0) {
          this.showNotifyWarning(this.$t('message.atcModalNoDataSalesChannels'))
        } else if (salesChannelsData === 0) {
          this.showNotifyWarning(
            this.$t('message.atcModalAllDataSalesChannelsUsed')
          )
        } else if (locationsData === 0) {
          this.showNotifyWarning(this.$t('message.atcModalNoDataLocations'))
        } else {
          this.$router.push('/available-to-commerce/new')
        }
      }
    },
    async getPageListData() {
      let params = { ...this.filters }
      let lid = params.salesChannelId
      lid = typeof lid !== 'string' && lid ? lid.join() : lid
      params.salesChannelId = lid
      params = Object.assign({}, this.paginations, params)
      await ApiAdm.get('/parameters', {
        params: params
      })
        .then((res) => {
          this.content = res.data
          this.content.data.forEach((obj) => {
            obj.deliveryMethods = obj.deliveryMethods
              ? obj.deliveryMethods
              : 'Não informado'
            obj.salesChannelName = this.getJoinList(obj.salesChannels)
            obj.locationName = this.getJoinList(obj.locations)
            obj.createdAt = this.$moment(obj.createdAt).format(
              'DD/MM/YYYY HH:mm:ss'
            )
            obj.updatedAt = this.$moment(obj.updatedAt).format(
              'DD/MM/YYYY HH:mm:ss'
            )
            return obj
          })
          this.content.page = this.paginations.page
        })
        .catch(() => {
          this.showNotifyInfo()
        })
    },
    editAtcConfig(i) {
      this.$router.push(`/available-to-commerce/edit/${i}`)
    },
    deleteAtcConfig(i, n) {
      this.showNotifyDelete(i, n)
    },
    seeMoreAtcConfig(param) {
      this.$router.push(`/available-to-commerce/view/${param.id}`)
    },
    setDeleteAtcConfig(i, n) {
      ApiAdm.delete(`/parameters/${i}`)
        .then(() => {
          this.showNotifySuccess(
            `"${n}" ${this.$t('message.atcModalDeleteConfigConfirm')} `
          )
        })
        .catch(() => {
          this.showNotifyDelete(i, n)
        })
    },
    chkDate(date) {
      return date.indexOf('T') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getJoinList(list) {
      return (
        list.map(({ name }) => name).join(',') ||
        this.$t('table.notInformation')
      )
    },
    getOptionsApi() {
      const orderDirection = this.options.sortDesc[0] === true ? 'desc' : 'asc'
      this.paginations = {
        perPage: this.options.itemsPerPage || '',
        page: this.options.page || '',
        orderBy: this.options.sortBy[0] || '',
        orderDirection: orderDirection
      }
      this.getPageListData()
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getPageListData()
    },
    showNotifySuccess(msg) {
      const options = {
        iconColor: 'success-100',
        icon: 'check_circle',
        confirmButtonColor: 'success-100',
        confirmLabel: 'Ok',
        type: 'info',
        text: msg
      }
      this.showNotify(options)
      this.getPageListData()
    },
    showNotifyDelete(i, n) {
      const options = {
        iconColor: 'error-100',
        icon: 'delete',
        declineButtonColor: 'error-100',
        confirmButtonColor: 'error-100',
        declineLabel: this.$t('button.cancel'),
        confirmLabel: this.$t('button.ok'),
        declineOutlined: true,
        type: 'question',
        text: `${this.$t('message.atcModalDeleteConfig')} "${n}"`
      }
      const info = {
        type: 'delete',
        id: i,
        name: n
      }
      this.showNotify(options, info)
    },
    showNotifyWarning(msg) {
      const options = {
        iconColor: 'warning-400',
        icon: 'warning',
        confirmButtonColor: 'warning-400',
        confirmLabel: this.$t('button.ok'),
        type: 'warning',
        text: msg
      }
      this.showNotify(options)
    },
    showNotifyInfo() {
      const options = {
        iconColor: 'information-100',
        icon: 'info_outline',
        confirmButtonColor: 'information-100',
        confirmLabel: this.$t('button.ok'),
        type: 'info',
        text: this.$t('message.atcModalError')
      }
      this.showNotify(options)
    },
    showNotify(options, info) {
      this.$notify(options).then(() => {
        if (info.type === 'delete') {
          this.setDeleteAtcConfig(info.id, info.name)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.ico-atc-modal {
  position: relative;
  display: flex;
  justify-content: center;
}
.atc-text-center {
  text-align: center;
}
</style>
