import Vue from 'vue'
import Vuex from 'vuex'
import Inventory from './Inventory'
import Notification from './Notification'
import Auth from './Auth'
import appShell from '@/store/appShell'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appShell,
    Inventory,
    Notification,
    Auth
  }
})
