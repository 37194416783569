<template>
  <v-row>
    <v-col cols="12">
      <ifc-switch
        v-model="isSwitch"
        :label="$t('label.InventoryProtectionPerUnit')"
        inset
        :disabled="false"
        :color="isSwitch === true ? 'success' : 'info'"
        class="ifc-switch-protection"
      />
      <ifc-table
        class="tab-stock-protection"
        :headers="isSwitch === true ? headers : headersOn"
        :items="content"
        :loading-text="$t('table.loadingText')"
        :no-data-text="$t('table.noDataText')"
        :no-results-text="$t('table.noResultText')"
      >
        <tbody>
          <tr v-for="(_, i) in content" :key="i">
            <td>
              {{ content[i].name }}
            </td>
            <td v-if="!isSwitch">
              <ifc-slider
                color="information-100"
                :min="0"
                :max="100"
                thumb-label
                :value="content[i].factor"
                thumbBackgroundColor="information-100"
                v-bind="$props"
                @input="
                  (n) => {
                    setStockFactor(n, i)
                  }
                "
              />
            </td>
            <td v-if="!isSwitch">
              <ifc-input
                class="input-width"
                :value="content[i].factor.toString()"
                @input="
                  (n) => {
                    setStockFactor(n, i)
                  }
                "
                suffix="%"
                mask="###"
              />
            </td>
            <td v-if="isSwitch">
              <input
                class="input-stock-protection"
                v-model="content[i].stockFactor"
                placeholder="0"
                type="number"
                min="0"
                oninput="this.value||(value='')"
              />
            </td>
          </tr>
        </tbody>
      </ifc-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Step4_StockProtection',
  props: {
    value: {
      require: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'name',
          class: 'text-left'
        },
        {
          text: this.$t('label.stockProtection'),
          sortable: true,
          value: 'factor',
          class: 'text-left'
        }
      ],
      headersOn: [
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'name',
          class: 'text-left'
        },
        {
          text: this.$t('label.stockProtection'),
          sortable: true,
          value: 'factor',
          class: 'text-left'
        },
        { text: '', sortable: true, value: 'factor', class: 'text-left' }
      ],
      paginations: [],
      filters: {},
      loading: false,
      isSwitch: false,
      clear: false
    }
  },
  watch: {
    isSwitch: {
      handler() {
        if (this.clear) {
          this.changeSwitch()
        }
        this.clear = true
      }
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getParameters', 'getChangeStockProtection']),
    content: {
      get() {
        return this.value
      },
      set(s) {
        this.$store.commit('Inventory/SET_LOCATION_CONFIG', s)
        this.$emit('input', s)
      }
    }
  },
  methods: {
    changeSwitch() {
      this.content.forEach((element) => {
        element.stockFactor = 0
      })
      if (this.isSwitch === false) {
        this.$store.commit('Inventory/SET_CHANGE_STOCK_PROTECTION', 'percent')
      } else {
        this.$store.commit('Inventory/SET_CHANGE_STOCK_PROTECTION', 'unit')
      }
      this.$store.commit('Inventory/SET_STOCKPROTECTION', {})
    },
    setStockFactor(val, i) {
      let valor = val
      valor = valor > 100 ? 100 : valor
      valor = valor < 0 || !valor ? 0 : valor
      const list = [...this.content]
      list[i].factor = valor
      this.content = list
    }
  },
  mounted() {
    if (this.getChangeStockProtection != null) {
      this.isSwitch = this.getChangeStockProtection !== 'percent'
    } else {
      this.isSwitch = this.getParameters.stockFactorType !== 'percent'
    }
    this.$nextTick(() => {
      this.clear = true
    })
  }
}
</script>

<style lang="scss">
.tab-stock-protection .ifc-table table tbody tr {
  td {
    &:last-child {
      width: 140px;
      .v-input__slot {
        background-color: transparent;
      }
      input {
        text-align: right;
      }
      .input-width * {
        border: none !important;
        width: 100px;
      }
    }
    &:nth-child(2) {
      width: 350px;
    }
    .v-messages,
    .v-text-field__details {
      display: none;
    }
    &:not(td:first-child) {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }
}
.ifc-switch-protection {
  .v-input__control {
    align-items: end;
    .v-input__slot {
      flex-direction: revert !important;
      margin-bottom: 0;
    }
  }
}
.input-stock-protection {
  width: 80px;
  height: 26px;
  border: 0.641975px solid #c6d3de;
  border-radius: 2.5679px;
  padding: 5px;
  font-size: 16px;
  &:focus {
    border-color: #c6d3de;
    outline: #c6d3de;
  }
}
</style>
