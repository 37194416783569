import { API_TOKEN, IS_DEV } from '@/constants/enviroments'

const storeDevelopment = {
  id: '61a9fbac5d4828001172df30',
  name: 'lorem',
  slug: 'lorem',
  token: API_TOKEN
}

export const DEFAULT_STATE = {
  store: IS_DEV
    ? storeDevelopment
    : {
        id: undefined,
        name: '',
        slug: '',
        token: ''
      }
}

export default DEFAULT_STATE
