import {
  INVENTORY_EXPORT_REPORT,
  STORE_STOCKS_IMPORT_FILE
} from '@/constants/notifications'
import {
  AVAILABLE_TO_COMMERCE_VIEW_NAME,
  INVENTORIES_NAME
} from '@/constants/routes'
import dayjs from '@/lib/dayjs'
import { getAtcReportNotify } from '@/services/report'
import { downloadReport } from '@/services/storeStocksImport'

const formatNotification = (notification) => {
  const now = dayjs()
  const getLabel = (minutes) => {
    if (minutes <= 1) return 'Agora'
    if (minutes < 60) return `${minutes} min atrás`
    const hours = Math.floor(minutes / 60)
    if (hours < 24) return `${hours} horas atrás`
    const days = Math.floor(hours / 24)
    if (days === 1) return `${days} dia atrás`
    return `${days} dias atrás`
  }

  const { createdAt, payload: configuration } = notification
  const minutes = now.diff(createdAt, 'minutes', false)
  const custom = {
    route: {
      name: INVENTORIES_NAME,
      text: 'Acessar Enterprise Inventory'
    },
    action: {},
    customLabels: {}
  }

  if (configuration.id) {
    custom.route = {
      name: AVAILABLE_TO_COMMERCE_VIEW_NAME,
      text: configuration.name,
      params: {
        id: configuration?.id
      }
    }
  }

  if (notification.type === INVENTORY_EXPORT_REPORT) {
    custom.route = {}
    custom.action = {
      method: () => getAtcReportNotify(configuration.id),
      label: 'Download',
      notifiedUser: configuration.id
    }
  }

  if (notification.type === STORE_STOCKS_IMPORT_FILE) {
    custom.type = `STORE_STOCKS_IMPORT_FILE_${configuration.status.toUpperCase()}`
    custom.route = {}
    custom.customLabels.name = configuration.fileName
      ? configuration.fileName
      : configuration.id

    if (configuration.status === 'finished') {
      custom.action = {
        method: () => downloadReport(configuration.id),
        label: 'Download',
        notifiedUser: configuration.id
      }
    }
  }

  return {
    ...notification,
    time: minutes,
    labelTime: getLabel(minutes),
    ...custom
  }
}

export default {
  SET_NOTIFICATIONS(state, payload) {
    const { data } = payload

    const notifications = data
      .sort((prev, next) => next.createdAt - prev.createdAt)
      .map(formatNotification)

    state.notifications = notifications
  },
  SET_MORE_NOTIFICATIONS(state, payload) {
    const oldNotifications = state.notifications
    const { data } = payload

    const notifications = data
      .sort((prev, next) => next.createdAt - prev.createdAt)
      .map(formatNotification)

    state.notifications = oldNotifications.concat(notifications)
  },
  ADD_NOTIFICATION(state, notification) {
    state.notifications.unshift(formatNotification(notification))
  },
  SET_UNREAD(state, total) {
    state.total = total
  },
  SET_ALL_LOADED(state, allLoaded) {
    state.allLoaded = allLoaded
  },
  INCREASE_UNREAD(state) {
    state.total = state.total + 1
  },
  DECREASE_UNREAD(state) {
    const total = state.total - 1
    state.total = total < 0 ? 0 : total
  },
  CLEAR_UNREAD(state) {
    state.total = 0
  },
  SET_ALL_READ(state) {
    const notifications = [...state.notifications]

    state.notifications = notifications.map((notification) => ({
      ...notification,
      read: true
    }))
  }
}
