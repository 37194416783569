<template>
  <div class="sku-detail-card">
    <ifc-card color="white" class="paddings">
      <div class="bell-ringin">
        <ifc-icon
          name="ph-bell-ringing"
          icon-color="neutral-400"
          icon-size="16"
        />
      </div>
      <ifc-typography
        :text="
          origin !== 'Manual'
            ? $t('label.integrationPerformed')
            : $t('label.manualRegistration')
        "
        typography-style="h3"
        class="title-card mb-5"
      />
      <div>
        <ul>
          <li class="ifc-mb-8" v-if="dateTime">
            <ifc-icon
              class="ifc-mr-12"
              name="ph-calendar-check"
              icon-color="neutral-400"
              icon-size="16"
            />
            <ifc-typography
              :text="dateTime"
              typography-style="body-small"
              text-weight="400"
              class="mt-1"
            />
          </li>
          <li class="ifc-mb-8">
            <ifc-icon
              class="ifc-mr-12"
              name="ph-check"
              icon-color="neutral-400"
              icon-size="16"
            />
            <ifc-typography
              :text="`Quantidade: ${quantity}`"
              typography-style="body-small"
              text-weight="400"
              class="mt-1"
            />
          </li>
          <li class="ifc-mb-8" v-if="origin">
            <ifc-icon
              class="ifc-mr-12"
              name="ph-map-pin"
              icon-color="neutral-400"
              icon-size="16"
            />
            <ifc-typography
              :text="`Origem: ${origin}`"
              typography-style="body-small"
              text-weight="400"
              class="mt-1"
            />
          </li>
          <li class="ifc-mb-8 ellipsis" v-if="!!user.email">
            <ifc-typography
              typography-style="body-small"
              text-weight="400"
              class="mt-1"
            >
              <ifc-tooltip
                bottom
                max-width="600"
                background-color="neutral-50"
                text-color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <ifc-icon
                      class="ifc-mr-12"
                      name="ph-envelope"
                      icon-color="neutral-400"
                      icon-size="16"
                    />
                    E-mail: {{ user.email }}
                  </span>
                </template>
                <span> E-mail: {{ user.email }} </span>
              </ifc-tooltip>
            </ifc-typography>
          </li>
        </ul>
      </div>
    </ifc-card>
  </div>
</template>

<script>
export default {
  name: 'SkuDetailCard',
  props: {
    dateTime: {
      type: String
    },
    quantity: {
      type: Number,
      default: 0
    },
    origin: {
      type: String
    },
    user: {
      type: Object
    }
  }
}
</script>
<style lang="scss">
.sku-detail-card {
  position: relative;
  .title-card {
    color: $color-information-100 !important;
  }
  .paddings {
    padding: 24px 48px;
  }
  ul {
    padding: 0 !important;
    li {
      display: flex;
      list-style: none;
    }
  }
  .bell-ringin {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    left: 15px;
    width: 30px;
    height: 30px;
    border-radius: 999px;
    background-color: $color-information-100;
    i {
      color: $color-shade-0;
    }
  }
  .ellipsis {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
</style>
