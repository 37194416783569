var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ifc-container',{attrs:{"lass":"atc","breadcrumb":_vm.breadcrumbs,"title":_vm.$t('pageAtcList.title')},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('notification-menu',{attrs:{"options":_vm.$notifications,"total-unread":_vm.$totalUnreadNotifications},on:{"onVisible":_vm.$handleChangeVisible,"onReadAll":_vm.$handleReadAll}})]},proxy:true}])},[_c('filter-list',{attrs:{"chkStore":_vm.chkStore},on:{"filters":_vm.filterTable,"register":_vm.chkDataConfig,"errApi":function($event){return _vm.showNotifyInfo()}}}),_c('ifc-table',{attrs:{"headers":_vm.headers,"items":_vm.content.data,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}}},[_c('tbody',_vm._l((_vm.content.data),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c('ifc-tooltip',_vm._b({attrs:{"bottom":"","max-width":"600","background-color":"information-50","text-color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"cell-ellipsis"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.salesChannelName.indexOf(',') > -1 ? item.salesChannelName.replaceAll(',', ', ') : item.salesChannelName)+" ")])]}}],null,true)},'ifc-tooltip',_vm.$props,false),[_c('span',{domProps:{"innerHTML":_vm._s(
                item.salesChannelName.indexOf(',') > -1
                  ? item.salesChannelName.replaceAll(',', ', ')
                  : item.salesChannelName
              )}})])],1),_c('td',[_c('ifc-tooltip',_vm._b({attrs:{"bottom":"","max-width":"600","background-color":"information-50","text-color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"cell-ellipsis"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.locationName.indexOf(',') > -1 ? item.locationName.replaceAll(',', ', ') : item.locationName)+" ")])]}}],null,true)},'ifc-tooltip',_vm.$props,false),[_c('span',{domProps:{"innerHTML":_vm._s(
                item.locationName.indexOf(',') > -1
                  ? item.locationName.replaceAll(',', ', <br>')
                  : item.locationName
              )}})])],1),_c('td',[_vm._v(_vm._s(item.createdAt))]),_c('td',[(item.active)?_c('ifc-chip',{attrs:{"label":_vm.$t(`status.active.short`),"background-color":"success-50","text-color":"success-200"}}):_c('ifc-chip',{attrs:{"label":_vm.$t(`status.inactive.short`),"background-color":"neutral-300","text-color":"neutral-400"}})],1),_c('td',[_c('ifc-button',{staticClass:"ifc-px-16",attrs:{"color":"primary","icon":"edit","size":"extra-small"},on:{"click":function($event){return _vm.editAtcConfig(item.id)}}})],1),_c('td',[_c('ifc-button',{staticClass:"ifc-px-16",attrs:{"color":"primary","icon":"delete","size":"extra-small"},on:{"click":function($event){return _vm.deleteAtcConfig(item.id, item.name)}}})],1),_c('td',[_c('ifc-button',{staticClass:"ifc-px-16",attrs:{"color":"primary","icon":"remove_red_eye","size":"extra-small"},on:{"click":function($event){return _vm.seeMoreAtcConfig(item)}}})],1)])}),0)]),_c('ifc-pagination',{staticClass:"ifc-mt-28",attrs:{"totalPages":_vm.content.pages,"perPageOptions":_vm.listPerPage,"perPageDefault":_vm.options.itemsPerPage},on:{"onChangePage":_vm.setOnChangePage,"onChangePerPage":_vm.setOnChangePerPage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }