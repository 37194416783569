<template>
  <ifc-container
    class="atc"
    :breadcrumb="[]"
    :title="`${$t('config.step')} ${step}: ${$t('config.title.step' + step)}`"
    :subtitle="id ? $t('pageAtcEdit.subtitle') : $t('pageAtcNew.subtitle')"
  >
    <template slot="header">
      <ifc-button
        prependIcon="close"
        class="ifc-btn-right"
        color="information-100"
        outlined
        :label="$t('button.cancel')"
        @click="goBack()"
      />
    </template>

    <v-row>
      <v-col cols="12" class="ifc-pt-none ifc-px-none">
        <ifc-card color="white" class="ifc-px-32">
          <ifc-stepper
            :activeStep="step"
            layout="HORIZONTAL"
            showDividers
            :steps="stepsList"
            value=""
          />
        </ifc-card>
      </v-col>
      <v-col class="ifc-px-none ifc-step-content">
        <step-info v-if="step === 1" v-model="config" />
        <step-sales-channels
          v-if="step === 2"
          v-model="config.salesChannels"
          :id-config="id"
          @errApi="showNotifyInfo()"
        />
        <step-locations
          v-if="step === 3"
          v-model="config.locations"
          @errApi="showNotifyInfo()"
        />
        <step-stock-and-distributor
          v-if="step === 4"
          v-model="config.locations"
          :sales-channels="config.salesChannels"
          @errApi="showNotifyInfo()"
        />
        <step-stock-protection v-if="step === 5" v-model="config.locations" />
        <step-sku-list
          v-if="step === 6"
          v-model="configLocationStepSku.locations"
          :set-location-list="config.locations"
          @errApi="showNotifyInfo()"
        />
      </v-col>
      <v-col cols="12" class="ifc-text-center ifc-pt-20">
        <ifc-button
          class="ifc-mx-10 btn-width"
          color="primary"
          prependIcon="arrow_back"
          outlined
          :label="$t('button.prev')"
          @click="step = step - 1"
          v-if="step > 1"
          size="medium"
        />
        <ifc-button
          class="ifc-mx-10 btn-width"
          color="information-100"
          appendIcon="arrow_forward"
          :label="$t('button.next')"
          @click="step = step + 1"
          v-if="stepsList.length !== step"
          :disabled="checkButton"
          size="medium"
        />
        <ifc-button
          class="ifc-mx-10 btn-width"
          color="information-100"
          prependIcon="check"
          :outlined="false"
          :label="$t('button.finish')"
          v-if="stepsList.length === step && !id"
          :disabled="checkButton"
          @click="setNewAtcConfig()"
          size="medium"
        />
        <ifc-button
          class="ifc-mx-10 btn-width"
          color="information-100"
          prependIcon="check"
          :outlined="false"
          :label="$t('button.finish')"
          v-if="stepsList.length === step && id"
          :disabled="checkButton"
          @click="setAtcConfig()"
          size="medium"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StepInfo from '@/components/Atc/Steps/StepInfo.vue'
import StepSalesChannels from '@/components/Atc/Steps/StepSalesChannels.vue'
import StepLocations from '@/components/Atc/Steps/StepLocations.vue'
import StepStockProtection from '@/components/Atc/Steps/StepStockProtection.vue'
import StepStockAndDistributor from '@/components/Atc/Steps/StepStockAndDistributor.vue'
import StepSkuList from '@/components/Atc/Steps/StepSkuList.vue'
export default {
  name: 'AtcRegistration',
  components: {
    StepInfo,
    StepSalesChannels,
    StepLocations,
    StepStockProtection,
    StepStockAndDistributor,
    StepSkuList
  },
  props: {
    id: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      config: {
        name: '',
        active: true,
        salesChannels: [],
        startDate: undefined,
        endDate: undefined,
        locations: []
      },
      configLocationStepSku: {
        locations: []
      },
      step: 1,
      checkButton: true,
      stepsList: [
        {
          index: 1,
          title: `${this.$t('config.step')} ${this.step}: ${this.$t(
            'config.title.step1'
          )}`,
          disabled: true,
          activeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          completeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          icon: {
            name: 'circle',
            color: '#FFFFFF !important',
            background: '#7676ff'
          }
        },
        {
          index: 2,
          title: `${this.$t('config.step')} ${this.step}: ${this.$t(
            'config.title.step2'
          )}`,
          disabled: true,
          activeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          completeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          icon: {
            name: 'circle',
            color: '#FFFFFF !important',
            background: '#7676ff'
          }
        },
        {
          index: 3,
          title: `${this.$t('config.step')} ${this.step}: ${this.$t(
            'config.title.step3'
          )}`,
          disabled: true,
          activeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          completeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          icon: {
            name: 'circle',
            color: '#FFFFFF !important',
            background: '#7676ff'
          }
        },
        {
          index: 4,
          title: `${this.$t('config.step')} ${this.step}: ${this.$t(
            'config.title.step4'
          )}`,
          disabled: true,
          activeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          completeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          icon: {
            name: 'circle',
            color: '#FFFFFF !important',
            background: '#7676ff'
          }
        },
        {
          index: 5,
          title: `${this.$t('config.step')} ${this.step}: ${this.$t(
            'config.title.step5'
          )}`,
          disabled: true,
          activeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          completeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          icon: {
            name: 'circle',
            color: '#FFFFFF !important',
            background: '#7676ff'
          }
        },
        {
          index: 6,
          title: `${this.$t('config.step')} ${this.step}: ${this.$t(
            'config.title.step5'
          )}`,
          disabled: true,
          activeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          completeIcon: {
            name: 'check',
            color: '#FFFFFF !important',
            background: '#7676ff'
          },
          icon: {
            name: 'circle',
            color: '#FFFFFF !important',
            background: '#7676ff'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Inventory', [
      'getDeselectedItens',
      'getLocationConfig',
      'getStockProtection',
      'getCriticalStock',
      'getChangeStockProtection'
    ]),
    ...mapState('Inventory', ['locationConfigProtection']),
    optionInfo() {
      return this.$store.state.Inventory.optionInfo
    },
    parameters() {
      return this.$store.state.Inventory.parameters
    }
  },
  watch: {
    config: {
      handler() {
        this.validButton()
      },
      deep: true
    },
    step(val) {
      this.$store.commit('Inventory/SET_STEP', val)
      this.$store.commit('Inventory/SET_FILTER', {})
      this.validButton()
    },
    optionInfo: {
      handler() {
        const options = this.optionInfo
        if (options.type === 'info') {
          options.text = this.$t('message.atcModalError')
          options.confirmLabel = this.$t('button.ok')
        }
        if (options.type === 'success') {
          options.text = this.$t('message.atcModalSuccess')
        }
        this.showNotify(options)
      },
      deep: true
    },
    parameters: {
      handler() {
        const locations = this.parameters.locations.map((obj) => {
          obj.factor = obj.stockFactor * 100
          return obj
        })

        this.config = {
          ...this.parameters,
          locations
        }
      },
      deep: true
    }
  },
  methods: {
    processSellersValue(locations) {
      const sellersOptions = this.$store.state.Inventory.sellersOptions || []

      return locations.map((location) => {
        return {
          ...location,
          sellers: location.sellers
            .map((sellerId) => {
              const seller = sellersOptions.find((s) => s.id === sellerId)

              if (!seller) return null

              return {
                id: seller.id,
                name: seller.name
              }
            })
            .filter(Boolean)
        }
      })
    },
    setNewAtcConfig() {
      const config = {
        ...this.config,
        locations: this.processSellersValue(this.config.locations)
      }
      this.$store.dispatch('Inventory/setNewAtcConfig', config)
      this.validButton(this.step)
    },
    getAtcConfig(id) {
      this.$store.dispatch('Inventory/getAtcConfig', id)
    },
    setAtcConfig() {
      const locations = this.getLocationConfig.map((obj) => {
        if (this.getChangeStockProtection === 'unit') {
          obj.stockFactor = parseInt(obj.stockFactor)
        } else {
          obj.stockFactor = obj.factor / 100
        }
        const excludeItems = this.getDeselectedItens
          .filter((item) => {
            return item.locationId === obj.id
          })
          .map((item) => {
            return item.skuId
          })
        obj.excludeItems = excludeItems
        obj.items = []
        if (this.locationConfigProtection[obj.id]) {
          this.locationConfigProtection[obj.id].forEach(
            (stockFactor, skuId) => {
              obj.items.push({
                skuId: skuId,
                stockFactor: parseInt(stockFactor)
              })
            }
          )
        }
        return obj
      })

      this.config.criticalStock = parseInt(this.getCriticalStock)
      this.config.stockFactorType = this.getChangeStockProtection

      const config = {
        ...this.config,
        locations: this.processSellersValue(locations)
      }

      this.$store.dispatch('Inventory/setAtcConfig', {
        params: config,
        id: this.id
      })
    },
    chkDate(date) {
      return date.indexOf('T') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getLocations(l) {
      const loc = l
      let cont = 0
      const result = loc.map((obj) => {
        cont++
        return cont > 1 ? `, ${obj.name}` : obj.name
      })
      return !result ? this.$t('table.notInformation') : result.join()
    },
    getOptionsApi() {
      let orderDirection = ''
      if (this.options.sortDesc[0] === true) {
        orderDirection = 'desc'
      } else {
        orderDirection = 'asc'
      }
      this.paginations = {
        perPage: this.options.itemsPerPage || '',
        page: this.options.page || '',
        orderBy: this.options.sortBy[0] || '',
        orderDirection: orderDirection
      }
      this.getListAtcConfig()
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getListAtcConfig()
    },
    validButton() {
      if (this.step === 1) {
        this.checkButton = !this.config.name
      } else if (this.step === 2) {
        this.checkButton = this.config.salesChannels.length < 1
      } else if (this.step === 3) {
        this.checkButton = this.config.locations.length < 1
      } else if (this.step === 4) {
        const canForward = this.config.locations
          ?.map((location) => location.sellers?.length > 0)
          .every(Boolean)
        this.checkButton = !canForward
      } else if (this.step === 5) {
        this.checkButton = this.config.locations < 1
      } else if (this.step === 6) {
        this.checkButton = this.config.locations.itens < 1
      }
    },
    goBack() {
      const options = {
        iconColor: 'information-100',
        icon: 'info_outline',
        confirmButtonColor: 'information-100',
        confirmLabel: this.$t('button.back'),
        confirmOutlined: true,
        declineButtonColor: 'information-100',
        declineLabel: this.$t('button.confirm'),
        type: 'question',
        text: this.$t('message.atcModalDiscartConfirm')
      }
      this.showNotify(options)
    },
    showNotifyInfo() {
      const options = {
        iconColor: 'information-100',
        icon: 'info_outline',
        confirmButtonColor: 'information-100',
        confirmLabel: this.$t('button.ok'),
        type: 'info',
        text: this.$t('message.atcModalError')
      }
      this.showNotify(options)
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {
          if (options.type === 'success') {
            this.$router.push('/available-to-commerce')
          }
        })
        .catch(() => {
          if (options.type === 'question') {
            this.$store.commit('Inventory/SET_STOCKPROTECTION', {})
            this.$router.push('/available-to-commerce')
          }
        })
        .finally(() => {
          this.$store.commit('Inventory/SET_OPEN_REGISTER', false)
        })
    }
  },
  mounted() {
    if (this.id) {
      this.getAtcConfig(this.id)
    }
  },
  destroyed() {
    this.$store.commit('Inventory/SET_CRITICALSTOCK', null)
  }
}
</script>

<style scoped>
header h2.ifc-container__title {
  padding-right: 140px;
}
.ifc-step-shadow {
  box-shadow: 0px 6px 12px 0px rgb(119 132 163 / 11%) !important;
}
.ifc-step-content {
  min-height: 45vh;
}
button.ifc-btn-right {
  position: relative;
  float: right;
}
button.btn-width {
  min-width: 180px !important;
}
</style>
