export default {
  SET_CURRENT_CONFIG(state, currentConfig) {
    state.currentConfig = currentConfig
  },
  SET_CURRENT_CONFIG_FROM_API(state, { currentPage, total, pages }) {
    state.currentConfig.page = currentPage
    state.pages = pages
    state.total = total
  },
  SET_PAGE(state, page) {
    state.currentConfig.page = page
  },
  SET_PER_PAGE(state, perPage) {
    state.currentConfig.itemsPerPage = perPage
  },
  SET_TABLE_LOADING(state, tableLoading) {
    state.tableLoading = tableLoading
  },
  SET_SALESCHANNEL(state, payload) {
    state.salesChannel = payload
  },
  SET_FILTER(state, payload) {
    state.filters = payload.filter
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload
  },
  SET_STORESTOCK(state, payload) {
    state.listStoreStock = payload
  },
  SET_NOTIFY(state, payload) {
    state.optionInfo = payload
  },
  SET_PARAMETER(state, payload) {
    state.parameters = payload
  },
  SET_INVENTORY_ID_DETAIL(state, inventory) {
    state.inventoryIdDetail = inventory
  },
  SET_STEP(state, payload) {
    state.step = payload
  },
  SET_DESELECTED_ITENS(state, payload) {
    state.deselectedItens = payload
  },
  SET_STOCKPROTECTION(state, payload) {
    state.setStockProtection = payload
  },
  SET_LOCATION_CONFIG(state, payload) {
    state.setLocationConfig = payload
  },
  SET_CRITICALSTOCK(state, payload) {
    state.criticalStock = payload
  },
  SET_CHANGE_STOCK_PROTECTION(state, payload) {
    state.changeStockProtection = payload
  },
  SET_LOCATION_CONFIG_PROTECTION(state, payload) {
    state.locationConfigProtection = payload
  },
  SET_OPEN_REGISTER(state, payload) {
    state.openRegister = payload
  },
  SET_SELLERS_OPTIONS(state, payload) {
    state.sellersOptions = payload
  }
}
