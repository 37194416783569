<template>
  <div class="filter-skus ifc-mb-16">
    <ifc-input
      v-model="filters.search"
      hide-details
      class="ifc-mt-20"
      :placeholder="`${$t('label.searchBy')} ${$t('label.name')} ${$t(
        'label.or'
      )} ${$t('label.externalSku')}`"
      prependIcon="search"
    />
    <ifc-combobox
      appendIcon="expand_more"
      hide-details
      v-model="filters.locationId"
      :label="getStep == '5' ? '' : $t('label.location')"
      :placeholder="getStep == '5' ? $t('label.location') : $t('label.select')"
      :options="locationsList"
      multiple
      clearable
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FilterSkus',
  props: ['locations'],
  data() {
    return {
      panel: [0],
      filters: {},
      locationsList: []
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('filters', this.filters)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getStep'])
  },
  methods: {
    getLocations() {
      const list = this.locations
      list.forEach((obj) => {
        this.locationsList.push({
          label: obj.name,
          value: obj.id
        })
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.getLocations()
    }, 10)
  }
}
</script>

<style lang="scss" scoped>
.filter-skus {
  & > .v-input .v-input__control .v-text-field__details {
    display: none !important;
  }
  display: flex;
  align-items: flex-end;
  gap: 8px;

  & > .ifc-input__container,
  & > .ifc-combobox__container {
    flex: 1;
  }
}
</style>
