import {
  fetchMarkAllRead,
  fetchMarkRead,
  fetchNotifications
} from '@/services/notifications'

export default {
  async getNotifications(
    { dispatch, commit },
    params = { page: 1, perPage: 10 }
  ) {
    const notifications = await fetchNotifications(params)
    commit('SET_UNREAD', notifications.unreadCount)
    commit('SET_ALL_LOADED', false)
    commit('SET_NOTIFICATIONS', notifications)
  },
  async getMoreNotifications(
    { state, commit },
    params = { page: 1, perPage: 10 }
  ) {
    if (state.allLoaded) return
    const notifications = await fetchNotifications(params)
    const { pages } = notifications
    const allLoaded = pages === params.page
    commit('SET_ALL_LOADED', allLoaded)
    commit('SET_MORE_NOTIFICATIONS', notifications)
  },
  async setRead({ commit }, payload) {
    await fetchMarkRead(payload)
    commit('DECREASE_UNREAD')
  },
  async setAllRead({ commit }) {
    await fetchMarkAllRead()
    commit('CLEAR_UNREAD')
    commit('SET_ALL_READ')
  }
}
