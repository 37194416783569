/* eslint-disable no-new */
import { io } from 'socket.io-client'
import store from '@/store'
import { Notification } from '@/plugins/socket/Notification'
import {
  API_TOKEN,
  API_KEY,
  IS_DEV,
  TENANT_ID,
  SOCKET_BASE_URL,
  SOCKET_PATH
} from '@/constants/enviroments'

const authToken = () => store.state.Auth.token
const authTenantId = () => store.state.Auth.account?.id
class Socket {
  #socket

  async initialize() {
    this.#socket = io(SOCKET_BASE_URL, {
      path: SOCKET_PATH,
      query: this.#auth,
      auth: this.#auth,
      transports: ['websocket']
    })

    this.#channels()
  }

  get #auth() {
    if (IS_DEV) {
      return {
        Authorization: API_TOKEN.replace('Bearer ', ''),
        'X-Tenant-Id': TENANT_ID,
        'api-key': API_KEY,
        room: 'inventory-' + TENANT_ID
      }
    }
    return {
      Authorization: authToken(),
      'X-Tenant-Id': authTenantId(),
      'api-key': API_KEY,
      room: 'inventory-' + authTenantId()
    }
  }

  #channels() {
    new Notification(this.#socket)
  }

  async disconnect() {
    await this.#socket.disconnect()
  }
}

export default Socket
