<template>
  <ifc-container :breadcrumb="breadcrumbs" :title="$t('pageList.title')">
    <div class="ifc-inventory-filter">
      <div class="ifc-inventory-filter__options">
        <ifc-input
          class="ifc-input-style"
          v-model="filters.search"
          clearable
          prependIcon="search"
          :placeholder="`${$t('label.searchBy')} ${$t('label.name')} ${$t(
            'label.or'
          )} ${$t('label.externalSku')}`"
        />
        <ifc-combobox
          class="ifc-combo-style"
          appendIcon="expand_more"
          v-model="filters.locationId"
          :label="`${$t('label.location')}`"
          placeholder="Selecione"
          keyLabel="name"
          keyValue="id"
          :loading="loading.filter"
          :options="locationsList"
          clearable
        />

        <ifc-combobox
          class="ifc-combo-style"
          appendIcon="expand_more"
          v-model="filters.storageAreaName"
          :label="`${$t('label.storageArea')}`"
          keyLabel="storageAreaName"
          keyValue="storageAreaName"
          placeholder="Selecione"
          :loading="loading.filter"
          :options="storageAreasListFormatter()"
          clearable
        />
      </div>
      <div class="ifc-inventory-filter__actions">
        <ifc-button
          class="ifc-px-16"
          color="information-100"
          to="/new"
          :label="$t('button.registrationStock')"
          appendIcon="add"
        />
        <ifc-button
          class="ifc-px-24"
          color="information-100"
          :label="$t('label.update')"
          appendIcon="sync"
          @click="getPageListData"
        />
      </div>
    </div>
    <ifc-table
      :loading="loading.table"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #imagem="{ item }">
        <img
          v-for="(image, index) in item.images"
          :key="index"
          :src="image.url"
          class="w-image-table"
          :alt="image.label"
          :title="image.label"
        />
      </template>
      <template #storageAreaName="{ item }">
        <div>
          {{ item.storageAreaName || $t('table.notInformation') }}
        </div>
      </template>
      <template #description="{ item }">
        <ifc-tooltip direction="bottom" :arrow="false">
          <template v-slot:activator="{ on, attrs }">
            <div class="cell-ellipsis" v-bind="attrs" v-on="on">
              {{ item.description }}
            </div>
          </template>
          <template>
            {{ item.description }}
          </template>
        </ifc-tooltip>
      </template>
      <template #actView="{ item }">
        <ifc-button
          class="ifc-px-16"
          icon="info_outline"
          size="extra-small"
          @click="handleOpenDetail(item)"
        />
      </template>
      <template #actEdit="{ item }">
        <ifc-button
          class="ifc-px-16"
          icon="edit"
          size="extra-small"
          @click="handleOpenEdit(item)"
        />
      </template>
      <template #updatedAt="{ item }">
        {{ dateFormat(item.updatedAt) }}
      </template>
      <template #skuId="{ item }">
        <div class="ifc-inventory-table__icon">
          <ifc-tooltip direction="top" max-width="300" v-if="!item.skuId">
            <template v-slot:activator="{ on, attrs }">
              <ifc-icon
                name="error_outline"
                type="filled"
                iconClass="color-black"
                iconColor="information-100"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <div class="ifc-tooltip__description">
              <strong>
                {{ $t('message.skuIdIncompatible') }}
              </strong>
              <small>
                {{ $t('message.stockNotFound') }}
              </small>
            </div>
          </ifc-tooltip>
        </div>
      </template>
    </ifc-table>
    <ifc-pagination
      class="ifc-inventory-table__pagination"
      ref="pagination"
      :totalPages="pageInfos.pages"
      :perPageOptions="[5, 10, 25, 50]"
      :perPageDefault="pageInfos.pageSize"
      @onChangePage="handleChangePage"
      @onChangePerPage="handleChangePerPage"
    />
    <inventory-detail v-if="open" v-model="open" :id="selectedInventory.id" />
  </ifc-container>
</template>

<script>
import { getStoreStocks } from '@/services/storeStocks'
import { getStoreAreas } from '@/services/storeAreas'
import { getAccountsLocation } from '@/services/accounts'
import MixinDebounce from '@/mixins/debounce'
import MixinCheckstore from '@/mixins/checkstore'
import InventoryDetail from '@/views/Inventory/ModalDetails.vue'

export default {
  name: 'PageInventory',
  mixins: [MixinDebounce, MixinCheckstore],
  components: {
    InventoryDetail
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.inventory.physicalStock')
        }
      ],
      headers: [
        { text: '', sortable: true, value: 'skuId' },
        {
          text: this.$t('label.externalSku'),
          sortable: true,
          value: 'externalSku'
        },
        { text: this.$t('label.image'), sortable: false, value: 'imagem' },
        {
          text: this.$t('label.name'),
          sortable: true,
          value: 'description',
          cellClass: 'cell-ellipsis'
        },
        { text: this.$t('label.quantity'), sortable: true, value: 'quantity' },
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'locationName'
        },
        {
          text: this.$t('label.inventoryType'),
          value: 'inventoryType'
        },
        {
          text: this.$t('label.lastUpdate'),
          sortable: true,
          value: 'updatedAt'
        },
        {
          text: this.$t('label.storageArea'),
          sortable: true,
          value: 'storageAreaName'
        },
        { text: '', value: 'actView', sortable: false },
        { text: '', value: 'actEdit', sortable: false }
      ],
      pageInfos: {
        pageSize: 10
      },
      options: {
        pageSize: 1,
        itemsPerPage: 10,
        pageCount: 1,
        page: 1
      },
      items: [],
      filters: {
        search: undefined,
        storageAreaName: undefined,
        locationId: undefined
      },
      loading: {
        table: false,
        filter: false
      },
      storageAreas: [],
      locationsList: [],
      selectedInventory: undefined,
      open: false,
      sortDesc: true,
      sortBy: 'updatedAt'
    }
  },
  mounted() {
    this.getListStorageAreas()
    this.getLocationList()
  },
  methods: {
    async getPageListData() {
      try {
        this.$set(this.loading, 'table', true)
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const { data, ...pageInfos } = await getStoreStocks(params)
        this.pageInfos = pageInfos
        this.items = data || []
      } finally {
        this.$set(this.loading, 'table', false)
      }
    },
    async getLocationList() {
      const locationsList = await getAccountsLocation()
      this.locationsList = locationsList
    },
    async getListStorageAreas() {
      try {
        this.$set(this.loading, 'filter', true)
        const params = {
          pageSize: Number.MAX_SAFE_INTEGER
        }

        const storageAreas = await getStoreAreas(params)
        this.storageAreas = storageAreas
      } catch {
        this.storageAreas = []
      } finally {
        this.$set(this.loading, 'filter', false)
      }
    },
    storageAreasListFormatter() {
      return this.storageAreas
        .filter((item) => item && item.name)
        .map((item) => item.name)
    },
    handleOpenDetail(inventory) {
      this.selectedInventory = inventory
      this.open = true
    },
    handleOpenEdit(inventory) {
      this.$router.push(`/edit/${inventory.id}`)
    },
    handleChangePage(page) {
      this.$set(this.options, 'page', page)
    },
    handleChangePerPage(itemsPerPage) {
      this.$set(this.options, 'itemsPerPage', itemsPerPage)
    },
    dateFormat(val) {
      return this.$moment(val).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.getPageListData()
      }
    },
    filters: {
      deep: true,
      handler() {
        this.$debounce(() => {
          this.getPageListData()
          this.$refs.pagination.onChangePage(1)
        })
      }
    },
    chkStore(store) {
      if (store) {
        this.getLocationList()
        this.getListStorageAreas()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ifc-tooltip__description * {
  display: block;
  text-align: center;
}
.ifc-inventory {
  &-table {
    &__icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__pagination {
      margin-top: 25px;
    }
  }
  &-filter {
    display: flex;
    grid-gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 6px;

    &__options {
      display: flex;
      grid-gap: 10px;
      align-items: flex-end;

      & > :nth-child(1) {
        flex: 0 0 45%;
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}
::v-deep .v-text-field__details {
  display: none;
}
@media (min-width: 991px) {
  .ifc-input-style {
    width: 370px;
  }
  .ifc-combo-style {
    width: 270px;
  }
}
::v-deep .ifc-combo-style {
  fieldset {
    height: 45px;
  }
  .ifc-combobox--ellipse {
    .v-input__slot {
      margin-bottom: 0;
      .v-select__slot {
        margin-top: -4px;
      }
    }
  }
}
</style>
