<template>
  <v-row>
    <v-col cols="12">
      <ifc-table
        class="tab-stock-and-distributor"
        :headers="headers"
        :items="content"
        :loading-text="$t('table.loadingText')"
        :no-data-text="$t('table.noDataText')"
        :no-results-text="$t('table.noResultText')"
      >
        <tbody>
          <tr v-for="(item, i) in content" :key="i">
            <td>
              {{ item.name }}
            </td>
            <td>
              <ifc-combobox
                class="ifc-combo-style"
                appendIcon="expand_more"
                v-model="item.sellers"
                placeholder="Selecione os distribuídores"
                keyLabel="name"
                keyValue="id"
                :loading="loading"
                :options="sellersOptions"
                :rules="$validator().isRequired().rules()"
                multiple
                clearable
                hide-details
                deletableChips
              />
            </td>
          </tr>
        </tbody>
      </ifc-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Step5_StockAndDistributor',
  props: {
    value: {
      required: true
    },
    salesChannels: {
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'name',
          class: 'text-left'
        },
        {
          text: this.$t('label.stockAndDistributorList'),
          sortable: false,
          class: 'text-left'
        }
      ],
      content: [] // Inicialize como uma matriz vazia
    }
  },
  computed: {
    loading() {
      return this.$store.state.Inventory.tableLoading
    },
    sellersOptions() {
      return this.$store.state.Inventory.sellersOptions
    }
  },
  methods: {
    getSellers() {
      const channelIds = this.salesChannels?.map((channel) => channel.id) || []

      this.$store.dispatch('Inventory/getListSellers', channelIds)
    }
  },
  watch: {
    content: {
      handler(newValue, oldValue) {
        if (
          JSON.parse(JSON.stringify(newValue)) !==
          JSON.parse(JSON.stringify(oldValue))
        ) {
          this.$emit('input', newValue)
        }
      },
      deep: true // Observa alterações profundas em content
    }
  },
  mounted() {
    // Inicialize content com a cópia de value
    this.content = this.value.map((item) => ({
      ...item,
      sellers: item.sellers?.map((seller) => seller) || []
    }))
    // Obter os vendedores iniciais
    this.getSellers()
  }
}
</script>

<style lang="scss">
.tab-stock-and-distributor .ifc-table table tbody tr {
  td {
    &:last-child {
      @media (min-width: 768px) {
        width: 40%;
      }
      width: 350px;
      .v-input__slot {
        background-color: transparent;
      }
    }
    .v-messages,
    .v-text-field__details {
      display: none;
    }
    &:not(td:first-child) {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }
}
.ifc-combo-style fieldset {
  height: auto !important;
}
.ifc-combo-style .v-select__selections {
  row-gap: 2px !important;
}
</style>
