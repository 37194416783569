<template>
  <ifc-dialog
    v-model="localOpen"
    :title="title"
    @onClose="handleClose"
    max-width="520px"
  >
    <v-row v-if="!loading">
      <v-col class="ifc-inventory-detail__quantity" cols="12">
        <QuantityCard :quantity="quantity" :title="$t('label.quantity')" />
      </v-col>
    </v-row>
    <SkuIdInfo class="mt-8" v-if="!loading" :InventoryIdDetail="inventory" />
    <div class="ifc-inventory-detail__loading" v-else>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      />
    </div>
    <v-row v-if="!loading" class="mt-8 pt-6 justify-center modal-footer">
      <ifc-button
        class="custom-icon ifc-px-24"
        size="small"
        color="information-100"
        :label="`${$t('button.inventoryRecord')}`"
        appendIcon="ph-wrench"
        @click="gotTo()"
      />
    </v-row>
  </ifc-dialog>
</template>

<script>
import { getStoreStock } from '@/services/storeStocks'
import QuantityCard from '@/components/QuantityCard.vue'
import SkuIdInfo from '@/components/SkuIdInfo.vue'
export default {
  name: 'InventoryDetail',
  components: {
    QuantityCard,
    SkuIdInfo
  },
  filters: {
    emptyText(value) {
      return value || 'Não informado'
    }
  },
  props: {
    id: {
      type: String
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    inventory: {},
    loading: false
  }),
  computed: {
    localOpen: {
      get() {
        return this.value
      },
      set(open) {
        this.$emit('input', open)
      }
    },
    title() {
      if (this.loading) return ''
      return this.inventory?.externalSku
        ? `${this.$t('label.skuId')}: ${this.inventory?.externalSku}`
        : this.$t('label.skuId') + ': ' + this.$t('status.null.text')
    },
    quantity() {
      return this.inventory?.quantity || 0
    }
  },
  mounted() {
    this.getSku()
  },
  methods: {
    handleClose() {
      this.$emit('input', false)
    },
    async getSku() {
      try {
        this.loading = true
        const data = await getStoreStock(this.id)
        this.inventory = data
        this.$store.commit('Inventory/SET_INVENTORY_ID_DETAIL', this.inventory)
      } finally {
        this.loading = false
      }
    },
    gotTo() {
      this.$router.push({
        path: '/sku-detail',
        query: { id: this.inventory.id }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@infralabs/infra-components-vue/dist/styles/scss/global/color';

.ifc-inventory-detail__quantity {
  display: flex;
  justify-content: center;
}
.ifc-inventory-detail__title {
  color: $color-neutral-400;
}

.ifc-inventory-detail__text {
  color: $color-neutral-600;
}

.ifc-inventory-detail__dialog {
  max-width: 404px;
}

.ifc-inventory-detail__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 438px;
}
</style>
<style lang="scss" scoped>
.modal {
  &-footer {
    border-top: 1px solid $color-neutral-200;
  }
}
.custom {
  &-icon {
    ::v-deep i {
      font-size: 12px;
    }
  }
}
</style>
