import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    $page: 1,
    $perPage: 10,
    $loading: false,
    $visible: undefined
  }),
  computed: {
    ...mapState('Notification', {
      $notifications: (state) => state.notifications,
      $totalUnreadNotifications: (state) => state.total,
      $allNotificationsLoaded: (state) => state.allLoaded
    }),
    $params() {
      const { $page, $perPage, $visible } = this.$data

      return {
        page: $page,
        perPage: $perPage,
        read: $visible
      }
    }
  },
  methods: {
    ...mapActions({
      $getNotifications: 'Notification/getNotifications',
      $getMoreNotifications: 'Notification/getMoreNotifications',
      $setAllRead: 'Notification/setAllRead'
    }),
    async $handleReadAll() {
      try {
        this.$loading.show()
        await this.$setAllRead()
      } finally {
        this.$loading.hide()
      }
    },
    async $handleChangeVisible(unread) {
      this.$data.$visible = unread ? false : undefined
      this.$data.$page = 1
      this.$fetchNotifications()
    },
    async $fetchNotifications() {
      try {
        this.$loading.show()
        await this.$getNotifications(this.$params)
      } finally {
        this.$loading.hide()
      }
    },
    async $fetchMoreNotifications() {
      try {
        this.$loading.show()
        await this.$getMoreNotifications(this.$params)
      } finally {
        this.$loading.hide()
      }
    },
    $addPageNotification() {
      this.$data.$page = this.$data.$page + 1
    },
    $setNotificationPerPage(perPage) {
      this.$data.$perPage = perPage
    }
  }
}
