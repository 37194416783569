<template>
  <ifc-container
    class="atc"
    :breadcrumb="breadcrumbs"
    :title="`${this.$t('pageAtcView.title')} ${accountName}`"
    :subtitle="`${$t('label.salesChannels')}: ${salesChannel.join(', ')}`"
    returnButton
    @onGoBack="getReturn"
  >
    <template slot="header">
      <ifc-chip
        class="ml-4"
        :label="
          config.active === true
            ? $t(`status.active.short`)
            : $t(`status.inactive.short`)
        "
        :background-color="
          config.active === true ? 'success-50' : 'neutral-300'
        "
        :text-color="config.active === true ? 'success-200' : 'neutral-400'"
      />
    </template>
    <v-row>
      <v-col class="ifc-px-none ifc-step-content">
        <purchase-preview
          v-if="config.locations.length > 0"
          :id="id"
          :config-params="config"
          v-model="config.locations"
          @errApi="showNotifyInfo()"
          @report="msgReport"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { ApiAdm } from '@/plugins/axios/axios'
import PurchasePreview from '@/components/Atc/View/PurchasePreview.vue'

export default {
  name: 'AtcView',
  components: {
    PurchasePreview
  },
  props: {
    id: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      config: {
        name: '',
        active: true,
        salesChannels: [],
        startDate: undefined,
        endDate: undefined,
        locations: []
      },
      checkButton: true,
      salesChannel: [],
      accountName: ''
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.atc.StockAvailableSale'),
          to: '/available-to-commerce',
          exact: true
        },
        {
          text: `${this.$t('breadcrumb.atc.PreviewPurchaseUnilever')} ${
            this.accountName
          }`
        }
      ]
    }
  },
  methods: {
    getAtcConfig(id) {
      this.loading = true
      ApiAdm.get(`/parameters/${id}`)
        .then((res) => {
          this.accountName = res.data.data.name
          this.config = res.data.data
          this.getSalesChannel(this.config.salesChannels)
          this.config.locations.forEach((obj) => {
            obj.factor = obj.stockFactor * 100
          })
        })
        .catch(() => {
          this.showNotifyInfo()
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSalesChannel(item) {
      item.forEach((obj) => {
        this.salesChannel.push(obj.name)
      })
    },
    msgReport(msg) {
      msg
        ? this.showNotifySuccess(
            this.$t('message.atcReportOkTxt'),
            this.$t('message.atcReportOkTit')
          )
        : this.showNotifyWarning(
            this.$t('message.atcReportErrTxt'),
            this.$t('message.atcReportErrTit')
          )
    },
    goBack() {
      this.$router.push('/available-to-commerce')
    },
    showNotifySuccess(msg, tit) {
      const options = {
        iconColor: 'success-100',
        icon: 'check_circle',
        confirmButtonColor: 'success-100',
        confirmLabel: 'Ok',
        type: 'success',
        text: msg,
        title: tit
      }
      this.showNotify(options)
    },
    showNotifyWarning(msg, tit) {
      const options = {
        iconColor: 'warning-400',
        icon: 'warning',
        confirmButtonColor: 'warning-400',
        confirmLabel: this.$t('button.ok'),
        type: 'warning',
        text: msg,
        title: tit
      }
      this.showNotify(options)
    },
    showNotifyInfo() {
      const options = {
        iconColor: 'information-100',
        icon: 'info_outline',
        confirmButtonColor: 'information-100',
        confirmLabel: this.$t('button.ok'),
        type: 'info',
        text: this.$t('message.atcModalError')
      }
      this.showNotify(options)
    },
    showNotify(options) {
      this.$notify(options)
        .then(() => {})
        .catch(() => {})
    },
    getReturn() {
      this.$router.go(-1)
    }
  },
  mounted() {
    if (this.id) {
      this.getAtcConfig(this.id)
    }
  }
}
</script>

<style scoped>
header h2.ifc-container__title {
  padding-right: 140px;
}
.ifc-step-shadow {
  box-shadow: 0px 6px 12px 0px rgb(119 132 163 / 11%) !important;
}
.ifc-step-content {
  min-height: 45vh;
}
button.ifc-btn-right {
  position: relative;
  float: right;
}
button.btn-width {
  min-width: 180px !important;
}
div.v-dialog.v-dialog--active {
  max-width: 365px !important;
}
</style>
