<template>
  <li class="ifc-notification__item">
    <div class="ifc-notification__item--centralized">
      <i :class="readClasses" />
    </div>
    <ifc-icon
      class="ifc-notification__item--centralized"
      :name="notificationStyle.icon"
      :iconColor="notificationStyle.color"
      iconSize="32"
    />
    <div class="ifc-notification__item--main">
      <h5 class="ifc-h5__regular-semi-bold">
        {{ notificationStyle.title }}
      </h5>
      <h6 class="ifc-h4__small-regular">
        {{ notificationStyle.text }}
      </h6>
      <small class="ifc-notification__item--link">
        <router-link v-if="route" :to="route" @click.native="handleVisited()">
          {{ route.text }}
        </router-link>
        <span v-if="action.method" @click="handleAction">
          {{ action.label }}
        </span>
      </small>
    </div>
    <span class="ifc-notification__item--time ifc-h5__regular-regular">
      {{ labelTime }}
    </span>
  </li>
</template>
<script>
import {
  NOTIFICATION_TYPE,
  NOTIFICATION_TYPES
} from '@/constants/notifications'
import { mapActions } from 'vuex'
export default {
  name: 'NotificationItem',
  props: {
    _id: {
      type: String,
      required: true
    },
    labelTime: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: (type) => NOTIFICATION_TYPES.includes(type)
    },
    read: {
      type: Boolean,
      default: false
    },
    route: {
      type: Object,
      required: true
    },
    action: {
      type: Object
    },
    customLabels: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: (vm) => ({
    localRead: vm.read
  }),
  computed: {
    name() {
      return this.route?.text
    },
    notificationStyle() {
      const notificationStyle = { ...NOTIFICATION_TYPE[this.type] }
      notificationStyle.text = this.replaceCustomLabels(notificationStyle.text)
      notificationStyle.text = notificationStyle.text.replace(
        '{name}',
        this.name
      )
      return notificationStyle
    },
    readClasses() {
      const suffix = !this.localRead ? 'visited' : 'unvisited'
      return `ifc-notification__item--${suffix}`
    }
  },
  methods: {
    ...mapActions({
      setRead: 'Notification/setRead'
    }),
    handleVisited(user = false) {
      this.setRead({ id: this._id, user })
      this.localRead = true
    },
    async handleAction() {
      try {
        this.$loading.show()
        await this.action.method()
        this.handleVisited(true)
      } catch {
        this.$notify({
          text: 'Não foi possível executar a ação',
          type: 'error'
        })
      } finally {
        this.$loading.hide()
      }
    },
    replaceCustomLabels(message) {
      for (const [key, value] of Object.entries(this.customLabels)) {
        message = message.replace(`{${key}}`, value)
      }
      return message
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@infralabs/infra-components-vue/dist/styles/scss/global/_color.scss';
.ifc-notification__list--small {
  & .ifc-h5__regular-semi-bold {
    font-size: 14px;
    line-height: 20px;
  }

  & .ifc-h4__small-regular {
    font-size: 12px;
    line-height: 18px;
  }

  & .ifc-notification__item--link {
    font-size: 10px;
    line-height: 18px;
  }

  & .ifc-notification__item--time {
    font-size: 12px;
    line-height: 18px;
  }
}

.ifc-notification__item {
  display: flex;
  grid-gap: 22px;
}

.ifc-notification__item--visited {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $color-success-100;
}

.ifc-notification__item--unvisited {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: transparent;
}

.ifc-notification__item--main {
  flex: 1;
}

.ifc-notification__item--centralized {
  display: flex !important;
  align-items: center;
}

.ifc-notification__item--time {
  color: #ceced2;
}

.ifc-notification__item--link > a,
.ifc-notification__item--link > span {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: $color-information-200;
  cursor: pointer;
}
</style>
