import { ApiInv } from '@/plugins/axios/axios'

const BASE_URL = '/store-stocks'

const formatParamsGetStoreStocksImport = (params) => {
  let orderDirection = null
  const { filters = {}, ...restParams } = params
  if (typeof restParams?.sortDesc?.[0] === 'boolean') {
    orderDirection = restParams?.sortDesc?.[0] ? 'desc' : 'asc'
  }

  const formattedParams = new URLSearchParams()
  formattedParams.append('perPage', restParams?.itemsPerPage || null)
  formattedParams.append('page', restParams?.page || null)
  formattedParams.append('orderBy', restParams?.sortBy?.[0] || null)
  formattedParams.append('orderDirection', orderDirection)

  for (const filterKey in filters) {
    if (filters[filterKey]) {
      if (!Array.isArray(filters[filterKey])) {
        formattedParams.append(filterKey, filters[filterKey])
      } else {
        filters[filterKey].forEach((filterValue) => {
          formattedParams.append(filterKey, filterValue)
        })
      }
    }
  }

  return formattedParams
}

export const getStoreStockImports = async (params) => {
  const { data } = await ApiInv.get(`${BASE_URL}/list-imports`, {
    params: formatParamsGetStoreStocksImport(params)
  })

  return data
}

export const downloadReport = async (importId) => {
  const { data, headers } = await ApiInv.get(
    `${BASE_URL}/download-errors-result/${importId}`,
    {
      timeout: Infinity,
      responseType: 'arraybuffer'
    }
  )

  const blob = new Blob([data], { type: headers['content-type'] })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = importId
  link.click()
  URL.revokeObjectURL(link.href)
}

export const uploadImportFile = async (locationId, importFile) => {
  const formData = new FormData()
  formData.append('file', importFile)
  formData.append('locationId', locationId)
  await ApiInv.post(`${BASE_URL}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
