<template>
  <v-menu offset-y rounded :closeOnContentClick="false">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="ifc-notification-menu">
        <span class="ifc-notification-menu__title">Notificações</span>
        <div v-if="totalUnread" class="ifc-notification-menu__badge">
          {{ totalUnread }}
        </div>
      </div>
    </template>
    <notification v-on="$listeners" :options="options" :max-height="480" small>
      <template #footer>
        <div class="ifc-notification-menu__link">
          <router-link to="/alert">
            Ver todas as minhas notificações recebidas
          </router-link>
        </div>
      </template>
    </notification>
  </v-menu>
</template>
<script>
import Notification from '@/components/Notification/notification.vue'

export default {
  name: 'NotificationMenu',
  components: {
    Notification
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    totalUnread: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@infralabs/infra-components-vue/dist/styles/scss/global/_color.scss';

.ifc-notification-menu {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  width: fit-content;
  cursor: pointer;
}

.ifc-notification-menu__badge {
  color: $color-shade-0;
  background-color: $color-error-300;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  border-radius: 50%;
}

.ifc-notification-menu__title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: $color-neutral-600;
}

.ifc-notification-menu__link {
  background-color: white;
  position: sticky;
  bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  text-decoration-line: underline;
  box-shadow: 1px 0px 14px 0px rgba(119, 132, 163, 0.15);
  margin-left: -20px;
  margin-right: -20px;
  height: 33px;

  & > a {
    color: $color-information-100;
  }
}
</style>
