<template>
  <ifc-container
    class="reservation"
    :breadcrumb="breadcrumbs"
    :title="$t('pageReservation.title')"
  >
    <v-row>
      <div class="d-flex w-full align-items-end">
        <v-col cols="10" class="ifc-pa-none">
          <filter-list @filters="filterTable" />
        </v-col>
        <v-col cols="2" class="d-flex w-full justify-content-end">
          <ifc-button
            class="ifc-px-24"
            color="information-100"
            :label="`${$t('label.update')}`"
            appendIcon="sync"
            @click="getOptionsApi"
          />
        </v-col>
      </div>
      <v-col cols="12" class="ifc-pa-none">
        <ifc-table
          :headers="headers"
          :items="content.data"
          :options.sync="options"
          :loading="loading"
        >
          <tbody v-if="content.data.length > 0">
            <tr v-for="(item, i) in content.data" :key="i">
              <td>{{ item.orderExternal }}</td>
              <td>{{ item.salesChannelName }}</td>
              <td>{{ item.locationName }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <ifc-chip
                  :label="$t(`status.${item.status}.short`)"
                  :textColor="item.colorStatus.text"
                  :backgroundColor="item.colorStatus.bg"
                />
              </td>
              <td>
                <ifc-button
                  class="ifc-px-16"
                  icon="info_outline"
                  :to="`/reservations/details/${item.id}`"
                  size="extra-small"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span>
                  {{ $t('table.noDataText') }}
                </span>
              </td>
            </tr>
          </tbody>
        </ifc-table>
        <ifc-pagination
          class="ifc-mt-28"
          :totalPages="content.pages"
          :perPageOptions="listPerPage"
          :perPageDefault="options.itemsPerPage"
          @onChangePage="setOnChangePage"
          @onChangePerPage="setOnChangePerPage"
        />
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { ApiRsv } from '@/plugins/axios/axios'
import FilterList from '@/components/Reservations/Filters/FilterReservation.vue'
import MixinCheckstore from '@/mixins/checkstore'

export default {
  name: 'ReservationList',
  mixins: [MixinCheckstore],
  components: {
    FilterList
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.reservation.StockReservation')
        }
      ],
      headers: [
        {
          text: this.$t('label.orderExternal'),
          sortable: true,
          value: 'orderExternal',
          class: 'text-left'
        },
        {
          text: this.$t('label.salesChannels'),
          sortable: true,
          value: 'salesChannelName',
          class: 'text-left'
        },
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'locationName',
          class: 'text-left'
        },
        {
          text: this.$t('label.reserveAt'),
          sortable: true,
          value: 'createdAt',
          class: 'text-left'
        },
        {
          text: this.$t('label.status'),
          sortable: true,
          value: 'status',
          class: 'text-center'
        },
        { text: '', sortable: false, value: 'action' }
      ],
      options: {},
      listPerPage: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 }
      ],
      content: {
        data: [],
        page: 1
      },
      config: [],
      paginations: [],
      filters: {},
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getOptionsApi()
      },
      deep: true
    }
  },
  methods: {
    getPageListData() {
      this.loading = true
      let params = {}
      params = Object.assign({}, this.paginations, this.filters)
      params.dateFrom = this.getDate(params.dateFrom)
      params.dateTo = this.getDate(params.dateTo)
      ApiRsv.get('/reservations', {
        params: params
      })
        .then((res) => {
          this.content = res.data
          this.content.data.forEach((obj) => {
            obj.orderExternal = obj.orderExternal
              ? obj.orderExternal
              : 'Não informado'
            obj.salesChannelName = obj.salesChannelName
              ? obj.salesChannelName
              : 'Não informado'
            obj.locationName = obj.locationName
              ? obj.locationName
              : 'Não informado'
            obj.createdAt = this.$moment(obj.createdAt).format(
              'DD/MM/YYYY HH:mm:ss'
            )
            obj.updatedAt = this.$moment(obj.updatedAt).format(
              'DD/MM/YYYY HH:mm:ss'
            )

            const stt = obj.status
            let colorStatus = { text: '', bg: '' }
            colorStatus =
              stt === 'confirmed'
                ? { text: 'success-200', bg: 'success-50' }
                : colorStatus
            colorStatus =
              stt === 'reserved'
                ? { text: 'information-100', bg: 'information-50' }
                : colorStatus
            colorStatus =
              stt === 'canceled'
                ? { text: 'error-100', bg: 'error-50' }
                : colorStatus
            colorStatus =
              stt === 'error'
                ? { text: 'error-50', bg: 'error-100' }
                : colorStatus
            colorStatus = !stt
              ? { text: 'neutral-400', bg: 'neutral-300' }
              : colorStatus
            obj.colorStatus = colorStatus

            return obj
          })
          this.content.page = this.paginations.page
          this.loading = false
        })
        .catch(() => {
          const options = {
            iconColor: 'information-100',
            confirmButtonColor: 'information-100',
            confirmLabel: this.$t('button.ok'),
            type: 'info',
            text: this.$t('message.atcModalError')
          }
          this.showAlert(options)
          this.loading = false
        })
    },
    chkDate(date) {
      return date.indexOf('T') > -1
        ? this.$moment(date).format('DD/MM/YYYY HH:mm:ss')
        : date
    },
    getDate(d) {
      let date = d
      if (date !== undefined && date.indexOf('/') > -1) {
        date = date.split('/').reverse()
        date[1] = date[1] > 9 ? date[1] : '0' + date[1]
        date[2] = date[2] > 9 ? date[2] : '0' + date[2]
        return `${date[0]}-${date[1]}-${date[2]}`
      } else {
        return undefined
      }
    },
    getOptionsApi() {
      let orderDirection = ''
      if (this.options.sortDesc[0] === true) {
        orderDirection = 'desc'
      } else if (this.options.sortDesc[0] === false) {
        orderDirection = 'asc'
      } else {
        orderDirection = undefined
      }
      this.paginations = {
        perPage: this.options.itemsPerPage || undefined,
        page: this.options.page || undefined,
        orderBy: this.options.sortBy[0] || undefined,
        orderDirection: orderDirection
      }
      this.getPageListData()
    },
    setOnChangePage(val) {
      this.options.page = val
    },
    setOnChangePerPage(val) {
      this.options.itemsPerPage = val
    },
    filterTable(filter) {
      this.paginations.page = 1
      this.filters = filter
      this.getPageListData()
    },
    showAlert(opt) {
      const options = opt
      this.$notify(options)
    }
  }
}
</script>
