<template>
  <ifc-card color="white" :class="classes">
    <div :style="styles">
      <section class="ifc-notification__title">
        <h4 class="ifc-h4__small-bold">Novos</h4>
        <div class="ifc-notification__not-read">
          <label class="ifc-h6__small-regular ifc-color--neutral-400">
            Mostrar apenas não lidas
          </label>
          <ifc-switch
            hide-details
            class="ifc-mt-none ifc-pt-none"
            color-thumb="success-100"
            inset
            :value="undefined"
            @input="handleChange"
          />
        </div>
      </section>
      <section class="ifc-notification__subtitle">
        <p class="ifc-regular-semi-bold">
          <template v-if="totalUnread">
            Você tem <span>{{ totalUnread }} notificações</span> não lidas
          </template>
        </p>
        <button v-if="totalUnread" @click="handleReadAll">
          Marca todas como lidas
        </button>
      </section>
      <ul :class="classesList" v-if="grouped.new.length">
        <notification-item
          v-for="option in grouped.new"
          :key="option.id"
          v-bind="option"
        />
      </ul>
      <template v-if="grouped.old.length">
        <h4 class="ifc-h4__small-bold ifc-pt-8">Anteriores</h4>
        <ul :class="classesList" :style="{ minHeight: 'auto!important' }">
          <notification-item
            v-for="option in grouped.old"
            :key="option.id"
            v-bind="option"
          />
        </ul>
      </template>
      <slot name="footer" />
    </div>
  </ifc-card>
</template>
<script>
import NotificationItem from '@/components/Notification/Item.vue'
import dayjs from '@/lib/dayjs'
export default {
  name: 'Notification',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number
    },
    options: {
      type: Array,
      default: () => []
    },
    totalUnread: {
      type: Number
    }
  },
  components: {
    NotificationItem
  },
  computed: {
    classes() {
      return {
        'ifc-notification': true,
        'ifc-notification--small': this.small
      }
    },
    styles() {
      if (!this.maxHeight) return {}
      return {
        height: `${this.maxHeight}px`
      }
    },
    classesList() {
      return {
        'ifc-notification__list': true,
        'ifc-notification__list--small': this.small
      }
    },
    grouped() {
      return this.options.reduce(
        (prevOption, currentOption) => {
          const today = dayjs()
          const diffDays = today.diff(currentOption.createdAt, 'days')
          diffDays > 0
            ? prevOption.old.push(currentOption)
            : prevOption.new.push(currentOption)
          return prevOption
        },
        {
          new: [],
          old: []
        }
      )
    }
  },
  methods: {
    handleChange(read) {
      this.$emit('onVisible', read)
    },
    handleReadAll() {
      this.$emit('onReadAll')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@infralabs/infra-components-vue/dist/styles/scss/global/_color.scss';
.ifc-notification {
  width: 635px;
  position: relative;
  overflow: auto;

  &--small {
    width: 385px;
  }
}

.ifc-notification__list {
  padding-left: 0;
  margin: 16px 0;
  min-height: 365px !important;

  & > li:not(:last-child) {
    border-bottom: 2px solid #e7ecf2;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  &--small > li.ifc-notification__item > .ifc-notification__item--centralized {
    background-color: red !important;
  }
}

.ifc-notification__not-read {
  display: flex;
  align-items: center;
}

.ifc-notification__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ifc-notification__subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-neutral-400;
  font-size: 14px;
  margin-top: 18px;
  margin-bottom: 21px;
  font-weight: 400;

  & > p {
    margin: 0;

    & > span {
      font-weight: 600;
      color: $color-information-100;
    }
  }

  & > button {
    cursor: pointer;
    color: $color-information-100;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
