import store from '@/store'
import { ApiMessageCenter } from '@/plugins/axios/axios'

const URL = '/notifications'

const getIds = () => {
  const tenantID = store.getters[`appShell/GET_ACCOUNT_ID`]
  const userId = store.getters[`appShell/GET_USER_ID`]

  return {
    tenantID,
    userId
  }
}

const getNotifiedUser = (tenantID, userId) => {
  let notifiedUser = 'inventory-'
  if (tenantID) notifiedUser += tenantID
  if (userId) notifiedUser += `,${userId}`
  return notifiedUser
}

export const fetchNotifications = async (params) => {
  const { tenantID, userId } = getIds()
  const defaultParams = {
    notifiedUser: getNotifiedUser(tenantID, userId)
  }
  const contactParams = { ...defaultParams, ...params }
  const { data = {} } = await ApiMessageCenter(URL, {
    params: contactParams
  })
  return data
}

export const fetchMarkRead = async ({ id, user }) => {
  const { tenantID, userId } = getIds()
  if (!id) throw Error('Id must required')
  const params = new URLSearchParams()
  params.append('notificationId', id)
  params.append(
    'notifiedUser',
    user ? userId : getNotifiedUser(tenantID, userId)
  )
  const { data = {} } = await ApiMessageCenter.put(`${URL}?${params}`)

  return data
}

export const fetchMarkAllRead = async () => {
  const { tenantID, userId } = getIds()
  const params = new URLSearchParams()
  params.append('all', true)
  params.append('notifiedUser', getNotifiedUser(tenantID, userId))
  const { data = {} } = await ApiMessageCenter.put(`${URL}?${params}`)

  return data
}
