<template>
  <ifc-container
    :breadcrumb="breadcrumbs"
    :title="$t('pageStoreStockImport.title')"
  >
    <template #action>
      <notification-menu
        @onVisible="$handleChangeVisible"
        @onReadAll="$handleReadAll"
        :options="$notifications"
        :total-unread="$totalUnreadNotifications"
      />
    </template>

    <v-row class="ifc-inventory-filter">
      <v-col lg="4" sm="6" cols="12">
        <ifc-input
          class="ifc-mt-20"
          v-model="filters.fileName"
          clearable
          prependIcon="search"
          :placeholder="`${$t('label.searchBy')} ${$t('label.filename')}`"
        />
      </v-col>
      <v-col lg="3" sm="6" cols="12">
        <ifc-combobox
          class="ifc-combo-style"
          appendIcon="expand_more"
          v-model="filters.status"
          :label="`${$t('label.status')}`"
          keyLabel="name"
          keyValue="id"
          placeholder="Selecione"
          :loading="loading.filter"
          :options="statusList"
          clearable
          multiple
        />
      </v-col>
      <v-col lg="2" sm="6" cols="12">
        <ifc-combobox
          class="ifc-combo-style"
          appendIcon="expand_more"
          v-model="filters.locationId"
          :label="`${$t('label.location')}`"
          placeholder="Selecione"
          keyLabel="name"
          keyValue="id"
          :loading="loading.filter"
          :options="locationsList"
          clearable
          multiple
        />
      </v-col>
      <v-col lg="3" sm="6" cols="12">
        <ifc-button
          class="ifc-px-14 ifc-mt-20"
          color="information-100"
          :label="$t('button.importStock')"
          appendIcon="upload"
          @click="handleOpenUpload"
        />
        <ifc-button
          class="ifc-px-14 ifc-ml-4 ifc-mt-20"
          color="information-100"
          :label="$t('label.update')"
          appendIcon="sync"
          @click="getPageListData"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="ifc-import__no_padding">
        <ifc-table
          :loading="loading.table"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #status="{ item }">
            <div>
              {{ $t(`importStatus.${item.status}`) }}
            </div>
          </template>
          <template #createdAt="{ item }">
            {{ dateFormat(item.createdAt) }}
          </template>
          <template #actDownReport="{ item }">
            <div class="errors">
              <ifc-button
                class="ifc-px-16"
                icon="download"
                size="extra-small"
                @click="handleDownloadReport(item)"
                v-show="item.status === 'finished' && item.totalErrorLines > 0"
              />
              <ifc-tooltip
                top
                max-width="600"
                background-color="information-50"
                text-color="primary"
                v-if="item.status === 'finished' && item.totalErrorLines === 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">-</div>
                </template>
                <span>{{ $t('message.importWithoutErrors') }}</span>
              </ifc-tooltip>
            </div>
          </template>
        </ifc-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="ifc-import__no_padding ifc-pt-12">
        <ifc-pagination
          class="ifc-inventory-table__pagination"
          ref="pagination"
          :totalPages="pageInfos.pages"
          :perPageOptions="[5, 10, 25, 50]"
          :perPageDefault="pageInfos.pageSize"
          @onChangePage="handleChangePage"
          @onChangePerPage="handleChangePerPage"
        />
      </v-col>
    </v-row>
    <store-stock-import-upload v-if="open" v-model="open" />
  </ifc-container>
</template>

<script>
import {
  getStoreStockImports,
  downloadReport
} from '@/services/storeStocksImport'
import { getAccountsLocation } from '@/services/accounts'
import MixinDebounce from '@/mixins/debounce'
import MixinCheckstore from '@/mixins/checkstore'
import MixinNotifications from '@/mixins/notifications'
import StoreStockImportUpload from '@/views/Store-Stock-Import/ModalUpload.vue'
import NotificationMenu from '@/components/Notification/menu'

export default {
  name: 'PageInventory',
  mixins: [MixinDebounce, MixinCheckstore, MixinNotifications],
  components: {
    StoreStockImportUpload,
    NotificationMenu
  },
  props: ['chkStore'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.storeStockImport.storeStock'),
          to: '/'
        },
        {
          text: this.$t('breadcrumb.storeStockImport.storeStockImport')
        }
      ],
      headers: [
        {
          text: this.$t('label.name'),
          sortable: true,
          value: 'fileName'
        },
        {
          text: this.$t('label.status'),
          sortable: true,
          value: 'status'
        },
        {
          text: this.$t('label.success'),
          sortable: true,
          value: 'totalSuccessLines'
        },
        {
          text: this.$t('label.error'),
          sortable: true,
          value: 'totalErrorLines'
        },
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'locationName'
        },
        {
          text: this.$t('label.date'),
          sortable: true,
          value: 'createdAt'
        },
        {
          text: this.$t('label.errorReport'),
          value: 'actDownReport',
          align: 'center',
          sortable: false
        }
      ],
      pageInfos: {
        pageSize: 10
      },
      options: {
        pageSize: 1,
        itemsPerPage: 10,
        pageCount: 1,
        page: 1
      },
      items: [],
      filters: {
        fileName: undefined,
        status: undefined,
        locationId: undefined
      },
      loading: {
        table: false,
        filter: false
      },
      statusList: [
        {
          id: 'pending',
          name: this.$t('importStatus.pending')
        },
        {
          id: 'processing',
          name: this.$t('importStatus.processing')
        },
        {
          id: 'finished',
          name: this.$t('importStatus.finished')
        }
      ],
      locationsList: [],
      selectedInventory: undefined,
      open: false,
      sortDesc: true,
      sortBy: 'createdAt'
    }
  },
  created() {
    this.$setNotificationPerPage(5)
    this.$fetchNotifications()
  },
  mounted() {
    this.getLocationList()
  },
  methods: {
    async getPageListData() {
      try {
        this.$set(this.loading, 'table', true)
        const params = Object.assign(
          {
            filters: this.filters
          },
          this.options
        )
        const { data, ...pageInfos } = await getStoreStockImports(params)
        this.pageInfos = pageInfos
        this.items = data
      } finally {
        this.$set(this.loading, 'table', false)
      }
    },
    async getLocationList() {
      const locationsList = await getAccountsLocation()
      this.locationsList = locationsList
    },
    handleOpenUpload() {
      this.open = true
    },
    handleDownloadReport(storeStockImport) {
      downloadReport(storeStockImport.id)
    },
    handleChangePage(page) {
      this.$set(this.options, 'page', page)
    },
    handleChangePerPage(itemsPerPage) {
      this.$set(this.options, 'itemsPerPage', itemsPerPage)
    },
    dateFormat(val) {
      return this.$moment(val).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        if (this.options.sortBy) {
          this.getPageListData()
        }
      }
    },
    filters: {
      deep: true,
      handler() {
        this.$debounce(() => {
          this.getPageListData()
          this.$refs.pagination.onChangePage(1)
        })
      }
    },
    chkStore(store) {
      if (store) {
        this.getLocationList()
        this.getListStorageAreas()
      }
    },
    open: {
      immediate: true,
      deep: true,
      handler(next, prev) {
        if (this.open === false && prev === true) {
          this.getPageListData()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ifc-import__no_padding {
  padding: 0px;
}
.ifc-tooltip__description * {
  display: block;
  text-align: center;
}
.ifc-inventory-filter > div {
  padding-right: 4px;
  padding-left: 0;
}
::v-deep .v-text-field__details {
  display: none;
}
::v-deep .ifc-combo-style {
  fieldset {
    height: 50px;
  }
  .v-input__slot {
    padding: 0;
  }
  .ifc-combobox--ellipse {
    .v-input__slot {
      margin-bottom: 0;
      .v-select__slot {
        margin-top: -4px;
      }
    }
  }
  .v-select__selections {
    flex-wrap: nowrap;
  }
}
.errors {
  display: flex;
  justify-content: center;
}
</style>
