<template>
  <ifc-container :breadcrumb="[]" class="ifc-inventory-alert">
    <notification
      @onVisible="$handleChangeVisible"
      @onReadAll="$handleReadAll"
      :options="$notifications"
      :total-unread="$totalUnreadNotifications"
      class="ifc-inventory--centralized"
    />
  </ifc-container>
</template>
<script>
import Notification from '@/components/Notification/notification.vue'
import MixinNotifications from '@/mixins/notifications'

export default {
  name: 'PageAlert',
  components: {
    Notification
  },
  mixins: [MixinNotifications],
  async created() {
    window.addEventListener('scroll', this.handleScroll)
    this.$setNotificationPerPage(10)
    this.$fetchNotifications()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(event) {
      const { scrollingElement } = event.target
      const { scrollHeight, scrollTop, clientHeight } = scrollingElement
      if (
        scrollHeight - scrollTop <= clientHeight &&
        !this.$allNotificationsLoaded
      ) {
        this.$addPageNotification()
        this.$nextTick(() => {
          this.$fetchMoreNotifications()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ifc-inventory-alert {
  margin: 0 auto;
}

.ifc-inventory--centralized {
  margin: 0 auto;
}
</style>
