<template>
  <v-row>
    <v-col cols="12">
      <filter-sales-channels @filters="filterTable" />
      <ifc-table
        v-model="selected"
        :headers="headers"
        :items="contentData"
        show-select
        item-key="name"
        :loading="loading"
        :loading-text="$t('table.loadingText')"
        :no-data-text="$t('table.noDataText')"
        :no-results-text="$t('table.noResultText')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterSalesChannels from '@/components/Atc/Filters/FilterSalesChannels.vue'

export default {
  name: 'Step2_SalesChannels',
  components: {
    FilterSalesChannels
  },
  props: {
    value: {
      type: Array,
      require: true
    },
    idConfig: {
      type: String,
      require: false
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('label.salesChannel'),
          sortable: true,
          value: 'name',
          class: 'text-left'
        },
        {
          text: this.$t('label.salesChannelType'),
          sortable: true,
          value: 'type',
          class: 'text-left'
        }
      ],
      timer: null
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getParameters', 'getOpenRegister']),
    selected: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    },
    loading() {
      return this.$store.state.Inventory.tableLoading
    },
    contentData() {
      return this.$store.state.Inventory.salesChannel
    }
  },
  methods: {
    filterTable(filter) {
      this.$store.commit('Inventory/SET_FILTER', {
        filter
      })
      this.$store.commit('Inventory/SET_PAGE', 1)

      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch('Inventory/getListSalesChannel', this.idConfig)
      }, 300)
    }
  },
  mounted() {
    this.$store.dispatch('Inventory/getListSalesChannel', this.idConfig)
    if (this.getOpenRegister !== true) {
      this.$store.commit(
        'Inventory/SET_CHANGE_STOCK_PROTECTION',
        this.getParameters.stockFactorType
      )
    }
  }
}
</script>
