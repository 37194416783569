<template>
  <v-row class="custom-padding h-24">
    <v-col sm="12" md="4">
      <v-col sm="12" class="box">
        <img
          v-if="InventoryIdDetail.images[0]"
          :src="InventoryIdDetail.images[0].url"
          :alt="InventoryIdDetail.images[0].label"
          width="100%"
        />
      </v-col>
    </v-col>
    <v-col sm="12" md="8" class="row">
      <v-col sm="12">
        <h6 class="ifc-inventory-detail__title ifc-h6__small-regular">
          {{ $t('label.description') }}
        </h6>
        <h5 class="ifc-inventory-detail__text ifc-h5__small-semi-bold">
          {{
            InventoryIdDetail.description
              ? InventoryIdDetail.description
              : $t('status.null.text')
          }}
        </h5>
      </v-col>
      <v-col
        v-for="option in options"
        :key="option.title"
        cols="12"
        sm="12"
        md="6"
      >
        <h6 class="ifc-inventory-detail__title ifc-h6__small-regular">
          {{ option.title }}
        </h6>
        <h5 class="ifc-inventory-detail__text ifc-h5__small-semi-bold">
          {{ option.text ? option.text : $t('status.null.text') }}
        </h5>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import formatDate from '@/utils/formatDate'
export default {
  name: 'SkuIdInfo',
  props: {
    InventoryIdDetail: {
      type: Object,
      required: true
    }
  },
  computed: {
    options() {
      return [
        {
          title: this.$t('label.location'),
          text: this.InventoryIdDetail?.locationName
        },
        {
          title: this.$t('label.createdAt'),
          text: formatDate(this.InventoryIdDetail?.createdAt)
        },
        {
          title: this.$t('label.storageArea'),
          text: this.InventoryIdDetail?.storageAreaName
        },
        {
          title: this.$t('label.inventoryType'),
          text: this.InventoryIdDetail?.inventoryType
        },
        {
          title: this.$t('label.updatedAt'),
          text: formatDate(this.InventoryIdDetail?.updatedAt)
        }
      ]
    }
  }
}
</script>
<style lang="scss">
@import '@infralabs/infra-components-vue/dist/styles/scss/global/color';

.sku-detail {
  .ifc-inventory-detail__quantity-card {
    width: 338px;
  }
  .custom {
    &-padding {
      padding: 10px 10px;
    }
  }
}
</style>
<style lang="scss" scoped>
.box {
  display: flex;
  background-color: #f5f5f5;
  height: 179px;
  justify-content: center;
  align-items: center;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
