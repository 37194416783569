import { ApiInv, ApiAdm } from '@/plugins/axios/axios'

const URL = '/store-stocks'

const formatParamsGetStoreStocks = (params) => {
  let orderDirection = null
  const { filters = {}, ...restParams } = params
  if (typeof restParams?.sortDesc?.[0] === 'boolean') {
    orderDirection = restParams?.sortDesc?.[0] ? 'desc' : 'asc'
  }

  const formattedParams = {
    perPage: restParams?.itemsPerPage || null,
    page: restParams?.page || null,
    orderBy: restParams?.sortBy?.[0] || null,
    orderDirection: orderDirection
  }

  for (const filterKey in filters) {
    if (filters[filterKey]) formattedParams[filterKey] = filters[filterKey]
  }

  return formattedParams
}

export const getStoreStocks = async (params) => {
  const { data } = await ApiInv.get(URL, {
    params: formatParamsGetStoreStocks(params)
  })

  return data
}

export const getStoreStock = async (stockId) => {
  if (!stockId) throw new Error('Stock id is required')
  const { data } = await ApiInv.get(`${URL}/${stockId}`)
  return data?.data
}

export const postStoreStock = async (payload) => {
  const { data } = await ApiAdm.post(URL, payload)

  return data
}

export const putStoreStock = async (stockId, locationId, payload) => {
  const { data } = await ApiAdm.put(`${URL}/${stockId}`, payload, {
    headers: {
      'X-Location-Id': locationId
    }
  })

  return data
}
