import axios from 'axios'
// import auth from '@/utils/app-shell-auth'
import {
  API_INV_ADM,
  API_INV_ENT,
  API_INV_RSV,
  // API_KEY,
  API_MESSAGECENTER,
  // API_TOKEN,
  IS_DEV,
  TENANT_ID
} from '@/constants/enviroments'

const defaultHeaders = () => {
  // const Authorization = IS_DEV ? { Authorization: `${API_TOKEN}` } : {}
  const xTenantId = {
    'X-Tenant-Id': IS_DEV
      ? TENANT_ID
      : window.parent.IFC_VARIABLES.headers['x-tenant-id']
  }
  // const apiKey = IS_DEV ? { 'api-key': API_KEY } : {}

  return {
    'Content-Type': 'application/json',
    // ...Authorization,
    ...xTenantId
    // ...apiKey
  }
}

const timeout = 30000

const ApiInv = axios.create({
  baseURL: API_INV_ENT,
  timeout
})

const ApiAdm = axios.create({
  baseURL: API_INV_ADM,
  timeout
})

const ApiRsv = axios.create({
  baseURL: API_INV_RSV,
  timeout
})

const ApiMessageCenter = axios.create({
  baseURL: API_MESSAGECENTER,
  timeout
})

ApiInv.interceptors.request.use(
  async (config) => {
    Object.assign(config.headers, {
      ...defaultHeaders()
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiAdm.interceptors.request.use(
  async (config) => {
    Object.assign(config.headers, {
      ...defaultHeaders()
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiRsv.interceptors.request.use(
  async (config) => {
    Object.assign(config.headers, {
      ...defaultHeaders()
    })
    return config
  },
  (error) => Promise.reject(error)
)

ApiMessageCenter.interceptors.request.use(
  async (config) => {
    Object.assign(config.headers, {
      ...defaultHeaders()
    })
    return config
  },
  (error) => Promise.reject(error)
)

export { ApiAdm, ApiInv, ApiMessageCenter, ApiRsv, axios }
