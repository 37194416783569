import { ApiRsv } from '@/plugins/axios/axios'

const teste = '/report'

export const setAtcReport = async (params) => {
  const data = await ApiRsv.post(`${teste}/available-stock`, params)
  return data
}

export const getAtcReportNotify = async (id) => {
  const { data, headers } = await ApiRsv.get(`${teste}/${id}`, {
    timeout: Infinity,
    responseType: 'arraybuffer'
  })
  const blob = new Blob([data], { type: headers['content-type'] })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  const now = Date.now()
  const label = `${id}_Estoque_Disponivel_${now}`
  link.download = label
  link.click()
  URL.revokeObjectURL(link.href)
}
