<template>
  <ifc-container
    :title="`SKU: ${getInventoryIdDetail.externalSku}`"
    :breadcrumb="breadcrumbs"
    returnButton
    @onGoBack="getReturn"
  >
    <v-row class="sku-detail">
      <v-col sm="12" md="5">
        <ListSkuDetailCard :listSku="getInventoryIdDetail.history" />
      </v-col>
      <v-col sm="12" md="7">
        <div>
          <div class="d-flex justify-end">
            <QuantityCard
              :quantity="quantity"
              :title="$t('label.quantitySku')"
              :is-loading="loading"
            />
          </div>
          <div class="mt-12">
            <ifc-card color="white" v-if="!loading">
              <SkuIdInfo :InventoryIdDetail="getInventoryIdDetail" />
            </ifc-card>

            <div class="ifc-inventory-detail__loading" v-else>
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStoreStock } from '@/services/storeStocks'
import QuantityCard from '@/components/QuantityCard.vue'
import SkuIdInfo from '@/components/SkuIdInfo.vue'
import ListSkuDetailCard from '@/components/Inventory/ListSkuDetailCard.vue'
export default {
  name: 'SkuDetail',
  components: { QuantityCard, SkuIdInfo, ListSkuDetailCard },
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.inventory.physicalStock'),
          to: '/',
          exact: true
        },
        {
          text: this.$t('breadcrumb.inventory.InventoryRecord')
        }
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getInventoryIdDetail']),
    quantity() {
      return this.getInventoryIdDetail?.quantity || 0
    }
  },
  created() {
    if (Object.keys(this.getInventoryIdDetail).length === 0) {
      this.getSku()
    }
  },
  methods: {
    async getSku() {
      try {
        this.loading = true
        const data = await getStoreStock(this.$route.query.id)
        this.$store.commit('Inventory/SET_INVENTORY_ID_DETAIL', data)
      } finally {
        this.loading = false
      }
    },
    getReturn() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
.sku-detail {
  .box {
    height: 237px;
  }
  .loading-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
