import { ApiAdm } from '@/plugins/axios/axios'

const URL = '/accounts/storage-areas'
const URL_CREATE = '/storage-areas'

export const getStoreAreas = async (params) => {
  const { data } = await ApiAdm.get(URL, {
    params: params || {}
  })

  return data?.data
}

export const getStoreAreasRegister = async (params) => {
  const { data } = await ApiAdm.get(URL_CREATE, {
    params: params || {}
  })

  return data?.data
}
