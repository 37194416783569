<template>
  <v-row>
    <v-col cols="12">
      <filter-locations @filters="filterTable" />
      <ifc-table
        v-model="selected"
        :headers="headers"
        :items="content.data"
        show-select
        item-key="id"
        :options.sync="options"
        :loading="loading"
        :loading-text="$t('table.loadingText')"
        :no-data-text="$t('table.noDataText')"
        :no-results-text="$t('table.noResultText')"
      />
      <ifc-pagination
        class="ifc-mt-28"
        :totalPages="getTotalPages.pages"
        :perPageOptions="listPerPage"
        :perPageDefault="options.itemsPerPage"
        @onChangePage="setOnChangePage"
        @onChangePerPage="setOnChangePerPage"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilterLocations from '@/components/Atc/Filters/FilterLocations.vue'
export default {
  name: 'Step3_Locations',
  components: {
    FilterLocations
  },
  props: {
    value: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'name',
          class: 'text-left'
        },
        {
          text: this.$t('label.shipmentType'),
          sortable: true,
          value: 'shipmentType',
          class: 'text-left cell-ellipsis'
        }
      ],
      content: {
        data: [],
        pages: 1
      },
      config: [],
      paginations: [],
      timer: null
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getTotalPages', 'currentConfigApi']),
    selected: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    },
    loading() {
      return this.$store.state.Inventory.tableLoading
    },
    contentData() {
      return this.$store.state.Inventory.locations
    },
    currentConfig() {
      return this.$store.state.Inventory.currentConfig
    },
    listPerPage() {
      return this.$store.state.Inventory.listPerPage
    },
    options: {
      get() {
        return this.$store.state.Inventory.currentConfig
      },
      set(currentConfig) {
        this.$store.commit('Inventory/SET_CURRENT_CONFIG', currentConfig)
      }
    },
    locationIds() {
      return this.value?.map(({ id }) => id)?.join(',')
    }
  },
  watch: {
    options: {
      handler() {
        this.getListLocations()
      },
      deep: true
    },
    contentData: {
      handler() {
        this.content.data = this.contentData.map((obj) => {
          const ships = [
            obj.shipToAddress ? this.$t('label.shipToAddress') : ' - ',
            obj.shipToStore ? this.$t('label.shipToStore') : ' - ',
            obj.pickupStore ? this.$t('label.pickupStore') : ' - '
          ]
          obj.shipmentType = ships
            .join()
            .replaceAll(', - ', '')
            .replaceAll(',', ', ')
          obj.factor = obj.stockFactor ? obj.stockFactor * 100 : 0
          obj.active = undefined
          obj.fiscalCode = undefined
          obj.description = undefined
          obj.siteUrl = undefined
          obj.icon = undefined
          obj.accountType = undefined
          obj.productOwner = undefined
          obj.salesProduct = undefined
          obj.fullfilment = undefined
          obj.defaultPriceStock = undefined
          obj.priceOwner = undefined
          obj.stockOwner = undefined
          obj.accountIDs = undefined
          obj.catalogIDs = undefined
          obj.offersFrom = undefined
          obj.salesChannels = undefined
          obj.sellers = obj.sellers ?? []
          return obj
        })
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('Inventory', ['getListLocations']),
    setOnChangePage(val) {
      this.$store.commit('Inventory/SET_PAGE', val)
    },
    setOnChangePerPage(val) {
      this.$store.commit('Inventory/SET_PER_PAGE', val)
      this.$store.commit('Inventory/SET_PAGE', 1)
    },
    filterTable(filter) {
      this.$store.commit('Inventory/SET_FILTER', {
        filter: { ...filter, accountType: 'location' }
      })
      this.$store.commit('Inventory/SET_PAGE', 1)
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch('Inventory/getListLocations')
      }, 300)
    }
  },
  mounted() {
    this.$store.commit('Inventory/SET_FILTER', {
      filter: {
        accountType: 'location'
      }
    })
    this.$store.dispatch('Inventory/getListLocations')
  }
}
</script>
