<template>
  <div class="ifc-filter-atc__config-list">
    <ifc-input
      v-model="filters.name"
      :placeholder="$t('label.searchByName')"
      prependIcon="search"
      clearable
      hide-details
    />
    <ifc-combobox
      appendIcon="expand_more"
      v-model="filters.salesChannelId"
      :label="$t('label.salesChannels')"
      :placeholder="$t('label.select')"
      :options="salesChannelsList"
      deletableChips
      multiple
      clearable
      hide-details
    />
    <ifc-combobox
      class="ifc-filter-atc__combobox"
      appendIcon="expand_more"
      v-model="filters.active"
      :label="$t('label.status')"
      :placeholder="$t('label.select')"
      :options="statusList"
      size="extra-small"
      clearable
      hide-details
    />
    <ifc-button
      class="ifc-px-24"
      color="information-100"
      appendIcon="add"
      label="Nova Configuração"
      @click="chkDataConfig"
      size="medium"
    />
  </div>
</template>

<script>
import { ApiAdm } from '@/plugins/axios/axios'
export default {
  name: 'FilterAtcConfig',
  props: ['chkStore'],
  data() {
    return {
      panel: [0],
      filters: {
        name: '',
        salesChannelId: ''
      },
      statusList: [
        {
          label: this.$t('status.active.short'),
          value: true
        },
        {
          label: this.$t('status.inactive.short'),
          value: false
        }
      ],
      salesChannelsList: []
    }
  },
  watch: {
    filters: {
      handler(f) {
        this.$emit('filters', this.filters)
      },
      deep: true
    },
    chkStore(store) {
      if (store) {
        this.getSalesChannels()
      }
    }
  },
  methods: {
    chkDataConfig() {
      this.$emit('register')
      this.$store.commit('Inventory/SET_OPEN_REGISTER', true)
      this.$store.commit('Inventory/SET_CHANGE_STOCK_PROTECTION', 'percent')
    },
    getSalesChannels() {
      ApiAdm.get('/parameters/sales-channels?hasParameter=true&perPage=50')
        .then((res) => {
          const data = res.data.data
          data.forEach((obj) => {
            obj.label = obj.name
            obj.value = obj.id
          })
          this.salesChannelsList = data
        })
        .catch(() => {
          this.$emit('errApi')
        })
    }
  },
  mounted() {
    this.getSalesChannels()
  }
}
</script>

<style lang="scss">
.ifc-filter-atc__config-list {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  padding-bottom: 8px;

  & > div {
    flex: 1;
  }
}

.ifc-filter-atc__combobox {
  & .ifc-label {
    margin-bottom: 4px;
  }
}
</style>
