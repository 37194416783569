<template>
  <v-col cols="12" class="ifc-px-none">
    <ifc-card color="white" class="ifc-step-content ifc-px-32">
      <v-row>
        <v-col cols="12" sm="8" md="9" lg="9">
          <ifc-input
            :label="$t('config.name')"
            v-model="config.name"
            :placeholder="$t('config.name')"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3" class="align-self-center">
          <ifc-status-toggle
            v-model="config.active"
            :label="$t('config.status.title')"
            :trueLabel="$t('config.status.labelTrue')"
            :falseLabel="$t('config.status.labelFalse')"
          />
        </v-col>
        <ifc-accordion
          class="critical-stock"
          v-model="isOpen"
          :options="options"
        >
        </ifc-accordion>
      </v-row>
    </ifc-card>
  </v-col>
</template>

<script>
import InputCriticalStock from '../Partials/InputCriticalStock.vue'

export default {
  name: 'Step1_InfoConfig',
  props: {
    value: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      isOpen: 0
    }
  },
  computed: {
    options() {
      return [
        {
          title: this.$t('label.criticalStock'),
          color: 'default',
          prependIconColor: 'default',
          component: InputCriticalStock
        }
      ]
    },
    config: {
      get() {
        return this.value
      },
      set(c) {
        this.$emit('return', c)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@infralabs/infra-components-vue/dist/styles/scss/global/color';
.ifc {
  &-step {
    &-shadow {
      box-shadow: 0px 6px 12px 0px rgb(119 132 163 / 11%) !important;
    }
    &-content {
      min-height: 38vh;
    }
  }
}
.critical-stock {
  padding-bottom: 118px;
  ::v-deep {
    .ifc-accordion__header--default {
      background: #e7ecf2;
    }
  }
}
</style>
