import { jwtDecode } from '@/utils/jwt-decode'

export default {
  GET_ACCOUNT_ID: (state) => {
    return state?.store?.id
  },
  GET_USER_ID: (state) => {
    return jwtDecode(state?.store?.token)?.sub
  }
}
