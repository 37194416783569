<template>
  <ifc-main :isDev="isDev" data-app>
    <router-view :chkStore="chkStoreCurrent" />
  </ifc-main>
</template>
<script>
import store from '@/store'
import { mapMutations } from 'vuex'
import router from './router'
import { IS_DEV } from './constants/enviroments'

export default {
  name: 'App',
  props: ['tokenIdSubject', 'basepath', 'location'],
  data: () => ({
    subscription: {},
    chkStoreCurrent: '',
    newRoute: '',
    isDev: !!IS_DEV
  }),
  destroyed() {
    this.subscription && this.subscription.unsubscribe()
  },
  methods: {
    ...mapMutations({
      setStore: `appShell/SET_STORE`
    }),
    handleSubscribe({ store, authToken }) {
      store.token = authToken
      this.chkStoreCurrent = store
      this.setStore(store)
    },
    handleRouteChange(event) {
      router.push(`${event.detail.newRoute}`)
    }
  },
  watch: {
    tokenIdSubject: {
      immediate: true,
      handler(value) {
        if (!value) return
        this.subscription = value.subscribe(this.handleSubscribe)
      }
    },
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    },
    tenantId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$socket.initialize()
        }
      }
    }
  },
  computed: {
    tenantId() {
      return store.state.Auth.account?.id
    }
  },
  created() {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
          `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/inventory`,
          ''
        )
      : this.$route.fullPath

    console.log(this.newRoute)
  },
  mounted() {
    this.location.includes('old-app') && router.push(this.newRoute)
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>
<style lang="scss">
.v-application {
  background: #fafafc !important;
}
.ifc-plugins-notify-text {
  white-space: pre-line;
}
.ifc-dialog {
  line-break: normal !important;
}
</style>
