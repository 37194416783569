import { ApiAdm, ApiRsv } from '@/plugins/axios/axios'

export default {
  getListSellers({ commit }, salesChannelIds) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.get('/sellers', {
      params: {
        perPage: 9999,
        salesChannelIds: salesChannelIds?.join(',') || ''
      }
    })
      .then((res) => {
        commit('SET_SELLERS_OPTIONS', res.data.data)
      })
      .catch(() => {
        commit('SET_SELLERS_OPTIONS', [])

        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  getListSalesChannel({ getters, commit }, parameterId) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.get('/parameters/sales-channels?hasParameter=false', {
      params: {
        ...getters.currentConfigApi,
        ...getters.getFilters,
        parameterId
      }
    })
      .then((response) => {
        commit('SET_SALESCHANNEL', response?.data?.data)
        commit('SET_CURRENT_CONFIG_FROM_API', response?.data)
        return response.data
      })
      .catch(() => {
        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  getListLocations({ getters, commit }) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.get('/accounts', {
      params: {
        ...getters.currentConfigApi,
        ...getters.getFilters
      }
    })
      .then((res) => {
        commit('SET_LOCATIONS', res.data.data)
        commit('SET_CURRENT_CONFIG_FROM_API', res?.data)
        return res
      })
      .catch(() => {
        commit('SET_LOCATIONS', [])

        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  getParametersStoreStocks({ getters, commit }, { id, locationId }) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.get(`/parameters/${id}/store-stocks`, {
      params: {
        ...getters.currentConfigApi,
        ...getters.getFilters,
        locationId: locationId
      }
    })
      .then((res) => {
        commit('SET_STORESTOCK', res?.data?.data)
        commit('SET_CURRENT_CONFIG_FROM_API', res?.data)
      })
      .catch(() => {
        commit('SET_STORESTOCK', [])

        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  getListSku({ getters, commit }) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.get('/store-stocks', {
      params: {
        ...getters.currentConfigApi,
        ...getters.getFilters
      }
    })
      .then((res) => {
        commit('SET_STORESTOCK', res?.data?.data)
        commit('SET_CURRENT_CONFIG_FROM_API', res?.data)
      })
      .catch(() => {
        commit('SET_STORESTOCK', [])

        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  getListSku_ApiRsv({ getters, commit }) {
    commit('SET_TABLE_LOADING', true)
    ApiRsv.get('/store-stocks', {
      params: {
        ...getters.currentConfigApi,
        ...getters.getFilters
      }
    })
      .then((res) => {
        commit('SET_STORESTOCK', res?.data?.data)
        commit('SET_CURRENT_CONFIG_FROM_API', res?.data)
      })
      .catch(() => {
        commit('SET_STORESTOCK', [])

        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: 'Ok',
          type: 'info',
          text: 'Algo deu errado, tente mais tarde ou contate o suporte'
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  setNewAtcConfig({ getters, commit }, params) {
    commit('SET_TABLE_LOADING', true)
    params.locations = params.locations.map((obj) => {
      obj.stockFactor = obj.factor / 100
      return obj
    })
    ApiAdm.post('/parameters', params)
      .then(() => {
        commit('SET_NOTIFY', {
          iconColor: 'success-100',
          icon: 'check_circle',
          confirmButtonColor: 'success-100',
          confirmLabel: 'Ok',
          type: 'success',
          text: ''
        })
      })
      .catch(() => {
        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  setAtcConfig({ getters, commit }, { params, id }) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.patch(`/parameters/${id}`, params)
      .then(() => {
        commit('SET_NOTIFY', {
          iconColor: 'success-100',
          icon: 'check_circle',
          confirmButtonColor: 'success-100',
          confirmLabel: 'Ok',
          type: 'success',
          text: ''
        })
      })
      .catch(() => {
        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  },
  getAtcConfig({ commit }, id) {
    commit('SET_TABLE_LOADING', true)
    ApiAdm.get(`/parameters/${id}`)
      .then((res) => {
        const responseData = res.data.data
        const data = {
          ...responseData,
          locations: responseData?.locations.map((location) => {
            return {
              ...location,
              sellers: location.sellers?.map((seller) => seller.id) || []
            }
          })
        }
        commit('SET_PARAMETER', data)
      })
      .catch(() => {
        commit('SET_NOTIFY', {
          iconColor: 'information-100',
          icon: 'info_outline',
          confirmButtonColor: 'information-100',
          confirmLabel: '',
          type: 'info',
          text: ''
        })
      })
      .finally(() => {
        commit('SET_TABLE_LOADING', false)
      })
  }
}
