<template>
  <div>
    <filter-skus @filters="filterTable" :locations="locationList" />
    <ifc-table
      class="tab-stock-factor"
      v-model="selected"
      :headers="headers"
      :items="getListStoreStock"
      :options.sync="options"
      :loading="getTableLoadind"
      :loading-text="$t('table.loadingText')"
      :no-data-text="$t('table.noDataText')"
      :no-results-text="$t('table.noResultText')"
    >
      <tbody v-if="getListStoreStock && getListStoreStock.length > 0">
        <tr v-for="item in getListStoreStock" :key="item.id">
          <td>
            {{ item.externalSku }}
          </td>
          <td>
            <img
              v-if="item.images != null"
              :src="item.images[0].url"
              :alt="item.images[0].label"
              height="42"
            />
          </td>
          <td>
            <ifc-tooltip
              bottom
              max-width="600"
              background-color="information-50"
              text-color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                  {{ item.description }}
                </div>
              </template>
              <span>
                {{ item.description }}
              </span>
            </ifc-tooltip>
          </td>
          <td>
            {{ item.totalQuantity }}
          </td>
          <td>
            {{ item.reserved }}
          </td>
          <td>{{ item.threshold }}</td>
          <td>{{ item.available }}</td>
          <td>
            {{ item.locationName }}
          </td>
          <td>
            <ifc-tooltip
              bottom
              max-width="600"
              background-color="information-50"
              text-color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                  {{ item.sellersName }}
                </div>
              </template>
              <span>
                {{ item.sellersName }}
              </span>
            </ifc-tooltip>
          </td>
          <td>
            {{ item.inventoryType }}
          </td>
          <td>
            {{ item.updatedAt | moment('DD/MM/YYYY HH:mm') }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5">
            {{ $t('table.noResultText') }}
          </td>
        </tr>
      </tbody>
    </ifc-table>
    <ifc-pagination
      class="ifc-mt-28"
      :totalPages="getTotalPages.pages"
      :perPageOptions="getListPerPage"
      :perPageDefault="options.itemsPerPage"
      @onChangePage="changePage"
      @onChangePerPage="changePerPage"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FilterSkus from '@/components/Atc/Filters/FilterSkus.vue'
import { setAtcReport } from '@/services/report'
export default {
  name: 'PurchasePreview',
  components: {
    FilterSkus
  },
  props: {
    value: {
      type: Array,
      require: true
    },
    id: {
      type: String,
      require: true
    },
    configParams: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('label.externalSku'),
          sortable: true,
          value: 'externalSku'
        },
        {
          text: this.$t('label.image'),
          sortable: false,
          value: 'image'
        },
        {
          text: this.$t('label.name'),
          sortable: true,
          value: 'description'
        },
        {
          text: this.$t('label.physicalStock'),
          sortable: true,
          value: 'physicalStock'
        },
        {
          text: this.$t('label.reservedQuantity'),
          sortable: true,
          value: 'reserved'
        },
        {
          text: this.$t('label.protection'),
          sortable: true,
          value: 'threshold'
        },
        {
          text: this.$t('label.availableQuantity'),
          sortable: true,
          value: 'available'
        },
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'locationName'
        },
        {
          text: this.$t('label.sellers'),
          sortable: false,
          value: 'sellersName'
        },
        {
          text: this.$t('label.inventoryType'),
          sortable: true,
          value: 'inventoryType'
        },
        {
          text: this.$t('label.lastUpdate'),
          sortable: true,
          value: 'lastUpdate'
        }
      ],
      listPerPage: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 }
      ],
      enableTooltip: false,
      locationList: [],
      locationFilter: []
    }
  },
  computed: {
    ...mapGetters('Inventory', [
      'getTableLoadind',
      'getListStoreStock',
      'getTotalPages',
      'getListPerPage'
    ]),
    selected: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    },
    options: {
      get() {
        return this.$store.state.Inventory.currentConfig
      },
      set(currentConfig) {
        this.$store.commit('Inventory/SET_CURRENT_CONFIG', currentConfig)
      }
    },
    locationReport: {
      get() {
        const list = []
        this.configParams.locations.forEach((item) => {
          list.push(item.id)
        })
        return list
      }
    },
    salesChannelsReport: {
      get() {
        const list = []
        this.configParams.salesChannels.forEach((item) => {
          list.push(item.id)
        })
        return list
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.getListSku_ApiRsv()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('Inventory', ['getListSku_ApiRsv']),
    syncTable() {
      this.getListSku_ApiRsv()
    },
    changePage(page) {
      this.$store.commit('Inventory/SET_PAGE', page)
    },
    changePerPage(perPage) {
      this.$store.commit('Inventory/SET_PER_PAGE', perPage)
      this.$store.commit('Inventory/SET_PAGE', 1)
    },
    filterTable(filter) {
      this.locationFilter = filter.locationId
      this.$store.commit('Inventory/SET_PAGE', 1)
      this.setSelesChannelsLocations(filter)
      this.getListSku_ApiRsv()
    },
    resetFilter() {
      this.$store.commit('Inventory/SET_FILTER', {
        filter: {}
      })
    },
    showTooltip() {
      setTimeout(() => {
        this.enableTooltip = true
      }, 1000)
    },
    setSelesChannelsLocations(filter) {
      const list = []
      this.configParams.salesChannels.forEach((obj) => {
        list.push(obj.id)
      })
      const salesChannelId = list.join(',')
      this.$store.commit('Inventory/SET_FILTER', {
        filter: { ...filter, salesChannelId }
      })

      if (filter && filter.locationId && filter.locationId.length > 0) {
        const locationId = filter.locationId ? filter.locationId.join(',') : ''
        this.$store.commit('Inventory/SET_FILTER', {
          filter: { ...filter, locationId, salesChannelId }
        })
      } else {
        const location = this.locationList
        const locationFilter = []
        location.forEach((element) => {
          locationFilter.push(element.id)
        })
        const locationId = locationFilter.join(',')
        this.$store.commit('Inventory/SET_FILTER', {
          filter: { ...filter, locationId, salesChannelId }
        })
      }
    },
    requestReport() {
      const locations =
        this.locationFilter.length > 0
          ? this.locationFilter
          : this.locationReport
      const params = {
        data: {
          locations: locations,
          salesChannels: this.salesChannelsReport
        }
      }
      setAtcReport(params)
        .then(() => {
          this.$emit('report', true)
        })
        .catch(() => {
          this.$emit('report', false)
        })
    }
  },
  created() {
    this.resetFilter()
  },
  mounted() {
    this.locationList = [...this.value]
    this.showTooltip()
    this.setSelesChannelsLocations()
  }
}
</script>

<style>
.cell-ellipsis {
  white-space: nowrap;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
