export default {
  currentConfigApi: ({ currentConfig }) => {
    let orderDirection = null
    const currentConfigSortDesc = currentConfig.sortDesc

    if (Array.isArray(currentConfigSortDesc)) {
      if (typeof currentConfig.sortDesc[0] === 'boolean') {
        orderDirection = currentConfig.sortDesc[0] ? 'desc' : 'asc'
      }
    }

    return {
      perPage: currentConfig.itemsPerPage || null,
      page: currentConfig.page || null,
      orderBy: Array.isArray(currentConfigSortDesc)
        ? currentConfig.sortBy[0]
        : null,
      orderDirection
    }
  },
  getListStoreStock(state) {
    const {
      listStoreStock,
      setStockProtection,
      changeStockProtection,
      setLocationConfig
    } = state
    const newListStoreStock = listStoreStock.map((item) => {
      const storeStock = item
      let protection

      if (storeStock.sellers?.length > 0) {
        storeStock.sellersName = storeStock.sellers
          .map((seller) => seller.name)
          .join(', ')
      } else {
        storeStock.sellersName = ''
      }

      if (setStockProtection[storeStock.id] !== undefined) {
        protection = setStockProtection[storeStock.id] || 0
      } else {
        protection = storeStock.stockFactor
      }

      const getLocation = setLocationConfig?.find((element) => {
        return element.id === storeStock.locationId
      })

      if (!getLocation || !storeStock.quantity) {
        return storeStock
      }
      const factor = Number(getLocation.factor) / 100
      if (!storeStock.locationId) {
        return storeStock
      }

      if (changeStockProtection !== 'unit') {
        if (!protection) {
          storeStock.fixedQuantity = parseInt(
            storeStock.quantity - storeStock.quantity * factor
          )
          storeStock.fixed = true
          return storeStock
        }
        storeStock.fixedQuantity =
          parseInt(storeStock.quantity) - parseInt(protection)

        if (storeStock.fixedQuantity < 0) {
          storeStock.fixedQuantity = 0
        }
        return storeStock
      }

      const stockFactor = Number(getLocation.stockFactor)
      const valStock = !stockFactor
        ? storeStock.quantity
        : storeStock.quantity - stockFactor

      if (valStock < 0) {
        storeStock.fixedQuantity = 0
        return storeStock
      }

      if (!protection) {
        storeStock.fixedQuantity = valStock
        return storeStock
      }

      storeStock.fixedQuantity = storeStock.quantity - protection
      if (storeStock.fixedQuantity < 0) {
        storeStock.fixedQuantity = 0
      }

      return storeStock
    })

    return newListStoreStock
  },
  getErrorApi(state) {
    return state.errorApi
  },
  getFilters(state) {
    return state.filters
  },
  getSalesChannel(state) {
    return state.listSalesChannel
  },
  getTableLoadind(state) {
    return state.tableLoading
  },
  getLocations(state) {
    return state.locations
  },
  getTotalPages(state) {
    return {
      total: state.total,
      pages: state.pages
    }
  },
  getInventoryIdDetail(state) {
    return state.inventoryIdDetail
  },
  getListPerPage(state) {
    return state.listPerPage
  },
  getStep(state) {
    return state.step
  },
  getDeselectedItens(state) {
    return state.deselectedItens
  },
  getStockProtection(state) {
    return state.setStockProtection
  },
  getStockStockAndDistributor(state) {
    return state.setStockStockAndDistributor
  },
  getLocationConfig(state) {
    return state.setLocationConfig
  },
  getParameters(state) {
    return state.parameters
  },
  getCriticalStock(state) {
    return state.criticalStock
  },
  getChangeStockProtection(state) {
    return state.changeStockProtection
  },
  getOpenRegister(state) {
    return state.openRegister
  },
  getSellersOptions(state) {
    return state.sellersOptions
  }
}
