<template>
  <v-row class="ifc-filter-list ifc-pb-20 ifc-px-8">
    <v-col cols="12" sm="12" md="4" lg="3" class="ifc-px-4 c-h-internal">
      <ifc-input
        :label="$t('label.orderExternal')"
        :placeholder="$t('label.orderExternal')"
        v-model="filters.orderExternal"
        clearable
      />
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="3" class="ifc-px-4 c-h-internal">
      <ifc-input
        :label="`${$t('label.externalSku')} / ${$t('label.name')}`"
        :placeholder="`${$t('label.externalSku')} / ${$t('label.name')}`"
        v-model="filters.searchQuery"
        clearable
      />
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2" class="ifc-px-4 c-h-internal">
      <ifc-combobox
        class="ifc-combo-style"
        appendIcon="expand_more"
        v-model="filters.status"
        :options="statusList"
        :label="$t('label.status')"
        :placeholder="$t('label.select')"
        clearable
      />
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2" class="ifc-px-4 c-h-internal">
      <date-modal
        :label="$t('label.dateFrom')"
        v-model="filters.dateFrom"
        :dateEnd="filters.dateTo"
      />
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2" class="ifc-px-4 c-h-internal">
      <date-modal
        :label="$t('label.dateTo')"
        v-model="filters.dateTo"
        :dateStart="filters.dateFrom"
      />
    </v-col>
  </v-row>
</template>

<script>
import DateModal from '@/components/Modals/Date/DateModal.vue'
export default {
  name: 'FilterReservation',
  components: {
    DateModal
  },
  data() {
    return {
      panel: [0],
      filters: {
        order: undefined,
        search: undefined,
        orderExternal: undefined,
        status: undefined,
        dateFrom: undefined,
        dateTo: undefined
      },
      statusList: [
        {
          label: this.$t('status.reserved.short'),
          value: 'reserved'
        },
        {
          label: this.$t('status.confirmed.short'),
          value: 'confirmed'
        },
        {
          label: this.$t('status.canceled.short'),
          value: 'canceled'
        }
      ]
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('filters', this.filters)
      },
      deep: true
    },
    storageAreaItems() {
      setTimeout(() => {
        this.filters.storageAreaName = ''
      }, 1)
    }
  },
  methods: {
    collapseToogle() {
      this.panel = this.panel.indexOf(0) > -1 ? [] : [0]
    },
    clearFilters() {
      this.filters = {}
    },
    getToday() {
      const date = new Date()
      let day = date.getDate()
      day = day < 10 ? '0' + day : day
      let month = date.getMonth() + 1
      month = month < 10 ? '0' + month : month
      const year = date.getFullYear()
      return `${year}-${month}-${day}`
    },
    setDate(d) {
      const date = new Date(d)
      let day = date.getDate()
      day = day < 10 ? '0' + day : day
      let month = date.getMonth() + 1
      month = month < 10 ? '0' + month : month
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }
  }
}
</script>
<style lang="scss">
.ifc-filter-list {
  height: 100px;
  display: flex;
  align-items: flex-end;

  .v-input__control {
    height: 56px;
  }
  .c-h-internal {
    max-height: 88px;
  }
}
</style>
