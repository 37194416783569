export default {
  props: ['chkStore'],
  watch: {
    chkStore(store) {
      if (store) {
        this.getPageListData()
      }
    }
  }
}
