<template>
  <div class="list-sku-detail-card scrollbar ifc-pr-10 ifc-pt-16">
    <SkuDetailCard
      class="ifc-mb-40"
      v-for="(item, index) in listSku"
      :key="index"
      :date-time="$moment(item.createdAt).format('DD/MM/YYYY - HH:mm:ss')"
      :quantity="item.quantity"
      :origin="item.origin"
      :user="item.user || {}"
    />
    <ifc-card v-if="listSku === null" color="white">
      {{ $t('table.noResultText') }}
    </ifc-card>
  </div>
</template>

<script>
import SkuDetailCard from './SkuDetailCard.vue'
export default {
  name: 'ListSkuDetailCard',
  components: { SkuDetailCard },
  props: {
    listSku: {
      type: Array
    }
  }
}
</script>
<style lang="scss">
.list-sku-detail-card {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  .sku-detail-card {
    &::after {
      content: '|';
      width: 2px;
      color: $color-information-100;
      background-color: $color-information-100;
      position: absolute;
      left: 29px;
      font-size: 6px;
      height: 30px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  &.scrollbar {
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $color-neutral-300;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-neutral-400;
    }
  }
}
</style>
