<template>
  <v-row>
    <v-col cols="12">
      <filter-skus @filters="filterTable" :locations="locationList" />
      <ifc-table
        class="tab-stock-factor"
        v-model="selected"
        :headers="headers"
        :items="getListStoreStock"
        show-select
        item-key="id"
        @item-selected="comparingArray($event)"
        :options.sync="options"
        :loading="getTableLoadind"
        :loading-text="$t('table.loadingText')"
        :no-data-text="$t('table.noDataText')"
        :no-results-text="$t('table.noResultText')"
      >
        <template #description="{ item }">
          <ifc-tooltip
            bottom
            max-width="600"
            background-color="information-50"
            text-color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                {{ item.description }}
              </div>
            </template>
            <span>
              {{ item.description }}
            </span>
          </ifc-tooltip>
        </template>
        <template #stockFactor="{ item }">
          <input
            class="input-protection"
            v-model.number="item.stockFactor"
            v-on:keyup="changeStockProtection(item)"
            type="text"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          />
        </template>
        <template #fixedQuantity="{ item }">
          {{ item.fixedQuantity || 0 }}
        </template>
      </ifc-table>
      <ifc-pagination
        class="ifc-mt-28"
        :totalPages="getTotalPages.pages"
        :perPageOptions="getListPerPage"
        :perPageDefault="options.itemsPerPage"
        @onChangePage="changePage"
        @onChangePerPage="changePerPage"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import FilterSkus from '@/components/Atc/Filters/FilterSkus.vue'
export default {
  name: 'Step3_Locations',
  components: {
    FilterSkus
  },
  props: {
    value: {
      type: Array,
      require: true
    },
    setLocationList: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('label.sku'), sortable: true, value: 'externalSku' },
        {
          text: this.$t('label.name'),
          sortable: true,
          value: 'description'
        },
        {
          text: this.$t('label.location'),
          sortable: true,
          value: 'locationName'
        },
        {
          text: this.$t('label.quantity'),
          sortable: true,
          value: 'fixedQuantity'
        },
        {
          text: this.$t('label.inventoryType'),
          sortable: true,
          value: 'inventoryType'
        },
        {
          text: this.$t('label.stockProtection'),
          sortable: false,
          value: 'stockFactor'
        }
      ],
      enableTooltip: false,
      locationList: [],
      arrPayloadFormat: {},
      getListStoreStockStart: [],
      stockFactor: Number,
      selectedFilter: []
    }
  },
  computed: {
    ...mapGetters('Inventory', [
      'getTableLoadind',
      'getListStoreStock',
      'getTotalPages',
      'getListPerPage',
      'getDeselectedItens',
      'getStockProtection',
      'getChangeStockProtection',
      'getParameters',
      'getOpenRegister',
      'getFilters'
    ]),
    ...mapState('Inventory', ['locationConfigProtection']),
    selected: {
      get() {
        return this.value
      },
      set(s) {
        this.$emit('input', s)
      }
    },
    options: {
      get() {
        return this.$store.state.Inventory.currentConfig
      },
      set(currentConfig) {
        this.$store.commit('Inventory/SET_CURRENT_CONFIG', currentConfig)
      }
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.dispatchGetParametersStoreStocks()
      }
    },
    getListStoreStock: {
      handler() {
        this.generateSelectItem()
      },
      deep: false
    }
  },
  methods: {
    ...mapActions('Inventory', ['getParametersStoreStocks', 'getListSku']),
    changePage(page) {
      this.$store.commit('Inventory/SET_PAGE', page)
    },
    changePerPage(perPage) {
      this.$store.commit('Inventory/SET_PER_PAGE', perPage)
      this.$store.commit('Inventory/SET_PAGE', 1)
    },

    filterTable(filter) {
      this.selectedFilter = [...filter.locationId].join()
      this.$store.commit('Inventory/SET_PAGE', 1)
      this.startLocation(filter)
      this.dispatchGetParametersStoreStocks()
    },
    startLocation(filter) {
      if (filter && filter.locationId && filter.locationId.length > 0) {
        const locationId = filter.locationId ? filter.locationId.join(',') : ''
        this.$store.commit('Inventory/SET_FILTER', {
          filter: { ...filter, locationId }
        })
      } else {
        const location = this.locationList
        const locationFilter = []
        location.forEach((element) => {
          locationFilter.push(element.id)
        })
        const locationId = locationFilter.join(',')
        this.$store.commit('Inventory/SET_FILTER', {
          filter: { ...filter, locationId }
        })
      }
    },
    showTooltip() {
      setTimeout(() => {
        this.enableTooltip = true
      }, 1000)
    },
    comparingArray(payload) {
      if (payload.value === false) {
        this.$store.commit('Inventory/SET_DESELECTED_ITENS', [
          payload.item,
          ...this.getDeselectedItens
        ])
      } else {
        const positonIdex = this.getDeselectedItens.filter((element) => {
          return element.id !== payload.item.id
        })
        this.$store.commit('Inventory/SET_DESELECTED_ITENS', positonIdex)
      }
    },
    generateSelectItem() {
      this.getListStoreStock.forEach((element) => {
        if (!this.locationConfigProtection[element.locationId]) {
          this.locationConfigProtection[element.locationId] = new Map()
        }
        if (element.stockFactor !== null) {
          this.locationConfigProtection[element.locationId].set(
            element.skuId,
            element.stockFactor
          )
        }
        if (!element.stockFactor) {
          this.locationConfigProtection[element.locationId].delete(
            element.skuId
          )
        }
      })
      const selectItem = []
      this.arrPayloadFormat = {}

      this.getDeselectedItens.forEach((element) => {
        if (!Array.isArray(this.arrPayloadFormat[element.locationId])) {
          this.arrPayloadFormat[element.locationId] = []
        }
        this.arrPayloadFormat[element.locationId].push(element.id)
      })

      this.getListStoreStock.forEach((element) => {
        if (this.getStockProtection[element.id]) {
          element.stockFactor = this.getStockProtection[element.id]
        }

        let excludeItem = false
        if (this.arrPayloadFormat[element.locationId]) {
          excludeItem = this.arrPayloadFormat[element.locationId].some((id) => {
            return element.id === id
          })
        }
        if (!excludeItem) {
          selectItem.push(element)
        }
        this.selected = selectItem
      })
      this.arrPayloadFormat = { ...this.arrPayloadFormat }
    },
    changeStockProtection(item) {
      const stockFactor = { ...this.getStockProtection }
      stockFactor[item.id] = item.stockFactor
      this.$store.commit('Inventory/SET_STOCKPROTECTION', stockFactor)

      if (!this.locationConfigProtection[item.locationId]) {
        this.locationConfigProtection[item.locationId] = new Map()
      }

      this.locationConfigProtection[item.locationId].set(
        item.skuId,
        item.stockFactor
      )

      if (!item.stockFactor) {
        this.locationConfigProtection[item.locationId].delete(item.skuId)
      }
      this.$store.commit(
        'Inventory/SET_LOCATION_CONFIG_PROTECTION',
        this.locationConfigProtection
      )
    },
    initialSelected() {
      this.selected = [...this.locationList]
    },
    dispatchGetParametersStoreStocks() {
      if (this.getOpenRegister === false) {
        const params = {
          id: this.getParameters.id,
          locationId: this.selectedFilter || this.getLocationId()
        }
        this.$store.dispatch('Inventory/getParametersStoreStocks', params)
      } else {
        this.getListSku()
      }
    },
    getLocationId() {
      const listLocations = this.setLocationList
      const listIds = []
      listLocations.forEach((obj) => {
        listIds.push(obj.id)
      })
      return listIds.join()
    }
  },
  mounted() {
    this.locationList = [...this.setLocationList]
    this.showTooltip()
    this.initialSelected()
  }
}
</script>

<style lang="scss">
.cell-ellipsis {
  white-space: nowrap;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tab-stock-factor tr th:first-child div:first-child {
  display: none;
}
.input-protection {
  width: 57px;
  height: 26px;
  border: 0.641975px solid #c6d3de;
  border-radius: 2.5679px;
  padding: 5px;
  font-size: 16px;
  &:focus {
    border-color: #c6d3de;
    outline: #c6d3de;
  }
}
</style>
