const {
  NODE_ENV,
  VUE_APP_API_INV_ENT_PATH,
  VUE_APP_API_INV_ADM_PATH,
  VUE_APP_API_INV_RSV_PATH,
  VUE_APP_API_MESSAGECENTER_PATH,
  VUE_APP_I18N_LOCALE,
  VUE_APP_I18N_FALLBACK_LOCALE,
  VUE_APP_SOCKET_BASE_URL,
  VUE_APP_SOCKET_PATH
} = process.env

export const IS_DEV = NODE_ENV === 'development'

const getUrl = (path) => {
  if (IS_DEV) {
    return window.parent.IFC_VARIABLES.baseURL + path
  }

  return window.parent.IFC_VARIABLES.baseURL + path
}

export const TENANT_ID = window.parent.IFC_VARIABLES.headers['x-tenant-id']
export const API_KEY = window.parent.IFC_VARIABLES.headers['api-key']
export const API_TOKEN = window.parent.IFC_VARIABLES.auth.token

export const API_INV_ENT = getUrl(VUE_APP_API_INV_ENT_PATH)
export const API_INV_ADM = getUrl(VUE_APP_API_INV_ADM_PATH)
export const API_INV_RSV = getUrl(VUE_APP_API_INV_RSV_PATH)
export const API_MESSAGECENTER = getUrl(VUE_APP_API_MESSAGECENTER_PATH)

export const SOCKET_BASE_URL = getUrl(VUE_APP_SOCKET_BASE_URL)
export const SOCKET_PATH = VUE_APP_SOCKET_PATH

export const I18N_LOCALE = VUE_APP_I18N_LOCALE || 'pt-br'
export const I18N_FALLBACK_LOCALE = VUE_APP_I18N_FALLBACK_LOCALE || 'pt-br'

export { NODE_ENV }
