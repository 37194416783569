<template>
  <div class="ifc-inventory-detail__quantity-card">
    <img src="@/assets/image/hand_write.png" />
    <div class="ifc-inventory-detail__quantity-value">
      <span>{{ title }}</span>
      <span>{{ quantity | formatNumber }}</span>
    </div>
    <v-progress-circular
      v-if="isLoading"
      class="loading-center"
      :size="40"
      :width="7"
      color="primary"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: 'QuantityCard',
  filters: {
    formatNumber(value) {
      return Intl.NumberFormat('pt-BR').format(value)
    }
  },
  props: {
    quantity: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      require: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '@infralabs/infra-components-vue/dist/styles/scss/global/color';

.ifc-inventory-detail__quantity-card {
  position: relative;
  background-color: $color-information-100;
  min-width: 250px;
  min-height: 100px;
  box-shadow: 0px 5.398px 12.1455px rgba(119, 132, 163, 0.15);
  border-radius: 5.398px;
  display: flex;
  padding: 10px 0px;

  & > img {
    height: 120px;
    display: flex;
    flex-direction: column;
    margin-bottom: -30px;
  }
}

.ifc-inventory-detail__quantity-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0px 10px;
  color: $color-shade-0;

  & span:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.24px;
  }

  & span:nth-child(2) {
    font-size: 41px;
    font-weight: 900;
    line-height: 48.58px;
  }
}
</style>
