import StoreStockImportList from '@/views/Store-Stock-Import'
import InventoryList from '@/views/Inventory'
import InventoryRegistration from '@/views/Inventory/Registration.vue'
import SkuDetail from '@/views/Inventory/SkuDetail.vue'
import ReservationList from '@/views/Reservation'
import ReservationDetails from '@/views/Reservation/Details.vue'
import AtcList from '@/views/Atc'
import AtcView from '@/views/Atc/View.vue'
import AtcRegistration from '@/views/Atc/Registration.vue'
import Alert from '@/views/Alert/index.vue'
import {
  AVAILABLE_TO_COMMERCE_VIEW_NAME,
  INVENTORIES_NAME
} from '@/constants/routes'

export default [
  {
    path: '/',
    name: INVENTORIES_NAME,
    component: InventoryList,
    meta: { title: 'Inventories List' }
  },
  {
    path: '/inventories',
    name: INVENTORIES_NAME,
    component: InventoryList,
    meta: { title: 'Inventories List' }
  },
  {
    path: '/new',
    component: InventoryRegistration,
    meta: { title: 'New Inventory' }
  },
  {
    path: '/edit/:id',
    props: true,
    component: InventoryRegistration,
    meta: { title: 'Edit Inventory' }
  },
  {
    path: '/sku-detail',
    component: SkuDetail,
    meta: { title: 'SKU Detail' }
  },
  {
    path: '/reservations',
    component: ReservationList,
    meta: { title: 'Reservation List' }
  },
  {
    path: '/reservations/details/:id',
    props: true,
    component: ReservationDetails,
    meta: { title: 'Reservation Details' }
  },
  {
    path: '/available-to-commerce',
    props: true,
    component: AtcList,
    meta: { title: 'Available To Commerce List' }
  },
  {
    path: '/available-to-commerce/new',
    props: true,
    component: AtcRegistration,
    meta: { title: 'New Available To Commerce' }
  },
  {
    path: '/available-to-commerce/edit/:id',
    props: true,
    component: AtcRegistration,
    meta: { title: 'Edit Available To Commerce' }
  },
  {
    path: '/available-to-commerce/view/:id',
    props: true,
    name: AVAILABLE_TO_COMMERCE_VIEW_NAME,
    component: AtcView,
    meta: { title: 'View Available To Commerce' }
  },
  {
    path: '/alert',
    props: true,
    component: Alert,
    meta: { title: 'Alert' }
  },
  {
    path: '/store-stocks-import',
    component: StoreStockImportList,
    meta: { title: 'Store Stock Import' }
  }
]
