export default {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_SELLER(state, seller) {
    state.seller = seller
  },
  SET_ACCOUNT(state, account) {
    state.account = account
  },
  SET_USER_RULES(state, userRoles) {
    state.userRoles = userRoles
  }
}
