<template>
  <ifc-container
    :title="pageTitle"
    :breadcrumb="[
      { text: $t('breadcrumb.homePage'), disabled: true },
      {
        text: this.$t('breadcrumb.inventory.physicalStock'),
        to: '/',
        exact: true
      },
      { text: pageTitle }
    ]"
    returnButton
    @onGoBack="returnToInventory"
  >
    <ifc-card color="white" class="ifc-card-container">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <ifc-input
              :label="$t('label.externalSku')"
              :placeholder="$t('label.externalSku')"
              v-model="register.sku"
              :rules="$validator().isRequired().rules()"
              :disabled="isEditing"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <ifc-input
              :label="$t('label.name')"
              :placeholder="$t('label.name')"
              v-model="register.description"
              :rules="$validator().isRequired().rules()"
              :disabled="isEditing"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <ifc-combobox
              class="ifc-combo-style"
              appendIcon="expand_more"
              placeholder="Selecione"
              v-model="register.inventoryType"
              keyLabel="name"
              keyValue="value"
              :loading="loading.filter"
              :options="inventoryTypeList"
              :label="$t('label.inventoryType')"
              clearable
              :rules="$validator().isRequired().rules()"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <ifc-combobox
              class="ifc-combo-style"
              appendIcon="expand_more"
              :options="locationsList"
              :label="$t('label.location')"
              :rules="$validator().isRequired().rules()"
              :placeholder="$t('label.select')"
              v-model="register.location"
              keyLabel="name"
              keyValue="id"
              returnObject
              @change="chkStorageArea(register.location)"
              :disabled="isEditing"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <ifc-autocomplete
              :options="storageAreaItems"
              :label="$t('label.storageArea')"
              :placeholder="$t('label.select')"
              v-model="register.storageArea"
              :value="register.storageArea"
              keyLabel="name"
              keyValue="id"
              return-object
              :disabled="storageAreaItems.length === 0"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <ifc-input
              type="number"
              :min="0"
              :label="$t('label.quantity')"
              :placeholder="$t('label.quantity')"
              :rules="$validator().isMoreEqualThan(0).rules()"
              v-model.number="register.quantity"
            />
          </v-col>
        </v-row>
      </v-form>
    </ifc-card>
    <div class="ifc-mt-48 ifc-inventory-registraion__action">
      <ifc-button
        prependIcon="close"
        outlined
        color="information-100"
        :label="$t('button.cancel')"
        :loading="loading"
        @click="returnToInventory"
      />
      <ifc-button
        :label="$t('button.save')"
        color="information-100"
        appendIcon="check"
        :loading="loading"
        @click="onSubmit"
      />
    </div>
  </ifc-container>
</template>

<script>
import {
  postStoreStock,
  getStoreStock,
  putStoreStock
} from '@/services/storeStocks'
import { getStoreAreasRegister } from '@/services/storeAreas'
import { getAccountsLocation } from '@/services/accounts'
export default {
  name: 'InventoryRegistration',
  components: {},
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      register: {
        sku: null,
        description: null,
        quantity: null,
        storageArea: null,
        inventoryType: 'on-hand'
      },
      message: {},
      locationsList: [],
      storageAreaItems: [],
      inventoryTypeList: [
        {
          name: 'On hand',
          value: 'on-hand'
        },
        {
          name: 'Forecasted',
          value: 'forecasted'
        },
        {
          name: 'Infinito',
          value: 'infinite'
        }
      ],
      loading: false
    }
  },
  mounted() {
    this.getLocationList()
    if (this.id) {
      this.loadStockData()
    }
  },
  computed: {
    storageArea() {
      return (
        this.register.storageArea || {
          storageAreaName: '',
          _id: '',
          lockCodeName: ''
        }
      )
    },
    pageTitle() {
      return this.id
        ? this.$t('pageRegistrationEditSku.title')
        : this.$t('pageRegistrationNewSku.title')
    },
    isEditing() {
      return !!this.id
    }
  },
  methods: {
    async loadStockData() {
      try {
        const stockData = await getStoreStock(this.id)
        this.register = {
          sku: stockData.externalSku,
          description: stockData.description,
          inventoryType: stockData.inventoryType,
          location: stockData.locationId,
          quantity: stockData.quantity,
          storageArea: stockData.storageAreaId
        }
        await this.getStorageArea(stockData.locationId)
        this.register.storageArea = this.storageAreaItems.find((obj) => {
          return obj.id === stockData.storageAreaId
        })
      } catch (v) {
        await this.$notify({
          text: 'Não foi possível carregar o registro de estoque.',
          type: 'error'
        })
        this.returnToInventory()
      } finally {
        this.loading = false
      }
    },
    formIsValid() {
      const isValid = this.$refs.form.validate()
      return isValid
    },
    async onSubmit() {
      try {
        if (this.id) {
          if (!this.formIsValid()) return
          this.loading = true
          const payload = {
            quantity: this.register.quantity,
            storageAreaId: this.register.storageArea.id,
            inventoryType: this.register.inventoryType
          }

          await putStoreStock(this.id, this.register.location, payload)
          await this.$notify({
            text: 'Os dados foram salvos com sucesso',
            type: 'success'
          })
          this.returnToInventory()
        } else {
          if (!this.formIsValid()) return
          this.loading = true
          const { location, storageArea, inventoryType, ...restData } =
            this.register
          const locationInformation = {
            locationName: location.name,
            locationId: location.id,
            locationType: location.type
          }
          const storageInformation = {
            lockCode: storageArea.lockCodeName,
            storageAreaName: storageArea.attributes.storageAreaName,
            storageAreaId: storageArea._id || storageArea.id
          }
          const payload = {
            inventoryType: inventoryType,
            ...locationInformation,
            ...storageInformation,
            ...restData
          }
          await postStoreStock(payload)
          await this.$notify({
            text: 'Os dados foram salvos com sucesso',
            type: 'success'
          })
          this.returnToInventory()
        }
      } catch {
        this.$toastify('Ocorreu um erro inesperado', 'error')
      } finally {
        this.loading = false
      }
    },
    async getLocationList() {
      const locationsList = await getAccountsLocation()
      this.locationsList = locationsList
    },
    async getStorageArea(id) {
      let data
      try {
        this.loading = true
        data = await getStoreAreasRegister({
          pageSize: Number.MAX_SAFE_INTEGER,
          locationId: id
        })
        this.storageAreaItems = data.filter((obj) => {
          obj.name = obj.attributes.storageAreaName
          return obj
        })
      } catch {
        this.$toastify('Error ao encontrar as áreas de estoque', 'error')
      } finally {
        this.loading = false
      }
    },
    chkStorageArea(loc) {
      this.getStorageArea(loc.id)
    },
    returnToInventory() {
      this.$router.push({ path: '/' })
    }
  },
  watch: {
    storageAreaItems() {
      this.register.storageArea = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ifc-inventory-registraion__action {
  display: flex;
  justify-content: center;
  grid-gap: 20px;

  & > button {
    min-width: 180px !important;
  }
}
.ifc-card-container {
  @media (min-width: 1366px) {
    padding-left: 122px;
    padding-right: 164px;
  }
}
</style>
