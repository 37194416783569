import store from '@/store'

export class Notification {
  #store = 'Notification'
  #socket

  constructor(socket) {
    this.#socket = socket
    this.#onNotification()
  }

  #onNotification() {
    this.#socket.on('notification', (notification) => {
      store.commit(`${this.#store}/ADD_NOTIFICATION`, notification)
      store.commit(`${this.#store}/INCREASE_UNREAD`)
    })
  }
}
