<template>
  <v-row class="ifc-filter-list ifc-px-8">
    <v-col cols="12" sm="8" md="9" class="align-self-center ifc-px-4">
      <ifc-input
        v-model="filters.name"
        :placeholder="`${$t('label.searchBy')} ${$t('label.description')}`"
        prependIcon="search"
      />
    </v-col>
    <v-col cols="12" sm="4" md="3" class="align-self-end ifc-px-4">
      <ifc-combobox
        class="ifc-combo-style"
        appendIcon="expand_more"
        v-model="status"
        :options="statusList"
        :placeholder="$t('label.shippingMethod')"
        clearable
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FilterLocations',
  data() {
    return {
      panel: [0],
      status: null,
      filters: {
        name: ''
      },
      statusList: [
        {
          label: this.$t('label.shipToAddress'),
          value: 'shipToAddress'
        },
        {
          label: this.$t('label.shipToStore'),
          value: 'shipToStore'
        },
        {
          label: this.$t('label.pickupStore'),
          value: 'pickupStore'
        }
      ],
      salesChannelsList: []
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('filters', this.filters)
      },
      deep: true
    },
    status: {
      handler(s) {
        this.getStatus(s)
      },
      deep: true
    }
  },
  methods: {
    getStatus(s) {
      const name = this.filters.name
      const status = s
      this.filters = {
        name: name
      }
      if (status && status.indexOf('shipToAddress') > -1) {
        this.filters.shipToAddress = true
      }
      if (status && status.indexOf('shipToStore') > -1) {
        this.filters.shipToStore = true
      }
      if (status && status.indexOf('pickupStore') > -1) {
        this.filters.pickupStore = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-text-field__details {
  display: none !important;
}
</style>
