<template>
  <ifc-container
    class="reservation-details"
    :breadcrumb="breadcrumbs"
    :title="$t('pageReservationDetails.title')"
    returnButton
    @onGoBack="getReturn"
  >
    <v-row class="ifc-pb-40">
      <v-col cols="12" class="ifc-pa-none">
        <v-card class="ifc-step-shadow ifc-px-32 ifc-mb-48">
          <v-row class="bg-white details pb-5">
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.orderExternal')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.orderExternal }}</span>
              </ifc-typography>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.salesChannel')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.salesChannelName }}</span>
              </ifc-typography>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.account')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.accountName }}</span>
              </ifc-typography>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.status')
                }}</span>
              </ifc-typography>
              <ifc-chip
                :label="$t(`status.${content.status}.short`)"
                :text-color="content.colorStatus.text"
                :background-color="content.colorStatus.bg"
                v-if="content.colorStatus"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.location')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.locationName }}</span>
              </ifc-typography>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.locationCode')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.locationCode }}</span>
              </ifc-typography>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.reserveAt')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.createdAt }}</span>
              </ifc-typography>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <ifc-typography typography-style="h5" text-weight="400">
                <span class="ifc-color--neutral-400">{{
                  $t('label.updatedAt')
                }}</span>
              </ifc-typography>
              <ifc-typography typography-style="h4" text-weight="500">
                <span>{{ content.updatedAt }}</span>
              </ifc-typography>
            </v-col>
          </v-row>
        </v-card>
        <ifc-typography typography-style="h3" text-weight="700">
          <span>{{ $t('pageReservationDetails.subTitle') }}</span>
        </ifc-typography>
        <ifc-table :headers="headers" :items="content.data">
          <template #description="{ item }">
            <ifc-tooltip
              bottom
              max-width="600"
              background-color="information-50"
              text-color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="cell-ellipsis" v-bind="attrs" v-on="on">
                  {{ item.description }}
                </div>
              </template>
              <template>
                {{ item.description }}
              </template>
            </ifc-tooltip>
          </template>
          <template #action="{ item }">
            <div
              class="ifc-inventory-table__icon"
              @click="handleOpenDetail(item)"
            >
              <i class="material-icons-outlined">info</i>
            </div>
          </template>
        </ifc-table>
      </v-col>
    </v-row>
  </ifc-container>
</template>

<script>
import { ApiRsv } from '@/plugins/axios/axios'
export default {
  name: 'ReservationDetails',
  components: {},
  props: ['id'],
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('breadcrumb.homePage'),
          disabled: true
        },
        {
          text: this.$t('breadcrumb.reservation.StockReservation'),
          to: '/reservations',
          exact: true
        },
        {
          text: this.$t('breadcrumb.reservation.ReservationDetails')
        }
      ],
      account: { name: 'Infracommerce' },
      content: [],
      headers: [
        { text: this.$t('label.skuId'), sortable: false, value: 'externalSku' },
        {
          text: this.$t('label.description'),
          sortable: false,
          value: 'description'
        },
        { text: this.$t('label.quantity'), sortable: false, value: 'quantity' }
      ],
      loading: false
    }
  },
  methods: {
    getDetailsSku() {
      this.loading = true
      const url = `/reservations/${this.id}`
      ApiRsv.get(url)
        .then((res) => {
          const result = res.data.data
          const stt = result.status
          let colorStatus = { text: '', bg: '' }
          colorStatus =
            stt === 'confirmed'
              ? { text: 'success-200', bg: 'success-50' }
              : colorStatus
          colorStatus =
            stt === 'reserved'
              ? { text: 'information-100', bg: 'information-50' }
              : colorStatus
          colorStatus =
            stt === 'canceled'
              ? { text: 'error-100', bg: 'error-50' }
              : colorStatus
          colorStatus =
            stt === 'error'
              ? { text: 'error-50', bg: 'error-100' }
              : colorStatus
          colorStatus = !stt
            ? { text: 'neutral-400', bg: 'neutral-300' }
            : colorStatus

          this.content = {
            accountName: result.account.name,
            salesChannelName: result.salesChannel.name,
            orderExternal: result.order.external,
            locationName: result.shipments[0].location.name,
            locationCode: result.shipments[0].location.code,
            status: result.status,
            colorStatus: colorStatus,
            createdAt: this.$moment(result.createdAt).format(
              'DD/MM/YYYY HH:mm:ss'
            ),
            updatedAt: this.$moment(result.updatedAt).format(
              'DD/MM/YYYY HH:mm:ss'
            ),
            data: result.shipments[0].items
          }
          this.loading = false
        })
        .catch(() => {
          const options = {
            iconColor: 'information-100',
            confirmButtonColor: 'information-100',
            confirmLabel: this.$t('button.ok'),
            type: 'info',
            text: this.$t('message.atcModalError')
          }
          this.showAlert(options)
          this.loading = false
        })
    },
    getReturn() {
      this.$router.go(-1)
    },
    showAlert(opt) {
      const options = opt
      this.$notify(options)
    }
  },
  mounted() {
    this.getDetailsSku()
  }
}
</script>

<style>
.reservation-details .ifc-step-shadow {
  box-shadow: 0px 6px 12px 0px rgb(119 132 163 / 11%) !important;
}
</style>
