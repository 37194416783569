<template>
  <div>
    <div class="input-critical-stock">
      <ifc-typography
        :text="$t('label.criticalStockMessage')"
        typography-style="h5-small"
        text-weight="700"
      />
      <ifc-input
        v-model="criticalStock"
        placeholder="00"
        type="number"
        min="0"
        oninput="this.value||(value='')"
        class="ifc-mx-10 ifc-input-120"
        size="small"
      />
      <ifc-typography
        :text="$t('label.unity')"
        typography-style="h5-small"
        text-weight="700"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'input-critical-stock',
  data: function () {
    return {
      criticalStock: null
    }
  },
  computed: {
    ...mapGetters('Inventory', ['getParameters', 'getCriticalStock'])
  },
  watch: {
    criticalStock: {
      handler() {
        this.updateStateCritialStock()
      }
    }
  },
  methods: {
    updateStateCritialStock() {
      this.$store.commit('Inventory/SET_CRITICALSTOCK', this.criticalStock)
    }
  },
  mounted() {
    if (this.getCriticalStock !== null) {
      this.criticalStock = this.getCriticalStock
    } else {
      this.criticalStock = this.getParameters.criticalStock
    }
  }
}
</script>

<style lang="scss">
.input-critical-stock {
  display: flex;
  align-items: center;
  label {
    margin: 30px 8px 0px;
    width: 115px;
  }
}
.ifc-input-120 {
  max-width: 120px;
}
</style>
